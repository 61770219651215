import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ListComponent = ({ data }) => {
  return <div style={{ whiteSpace: "pre-line" }}>{data}</div>;
};

const DefaultComponent = ({ data }) => {
  return <div>{data}</div>;
};

const determineFormat = (text) => {
  if (text.includes("\n") && text.includes(":")) {
    return "list";
  }
  return "default";
};

const SharedConversation = () => {
  const { conversationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    fetchConversationData(conversationId);
  }, [conversationId]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const fetchConversationData = (conversationId) => {
    setIsLoading(true);
    axios
      .get(
        `https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/share/messages`,
        {
          params: {
            conversationId: conversationId,
          },
        }
      )
      .then((res) => {
        const conversationData = res.data.data || [];
        setMessages(conversationData);
      })
      .catch((err) => {
        console.log("Error fetching conversation data:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
        <Typography variant="h4">
          <b>Generate Unique Shareable URL</b>
          <Divider variant="fullWidth" sx={{ color: "black", height: "2px" }} />
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: "2%" }}>
          {messages.map(({ id, text, sender, urls, actions }) => {
            actions = Array.isArray(actions) ? actions : [];
            const format = determineFormat(text);

            return (
              <div key={id} className={`message-container ${sender}`}>
                {sender === "maia" && (
                  <div className="message-logo">
                    <img
                      src="https://storage.googleapis.com/webui-assets/M_Logo-transparent.png"
                      alt="Maia Logo"
                    />
                  </div>
                )}

                {sender === "user" && (
                  <>
                    <div className="message-avatar">
                      <AccountCircleIcon />
                    </div>
                    <Typography variant="body1">
                      <b>Anonymous</b>
                    </Typography>
                  </>
                )}

                <div className="message-content">
                  {sender === "maia" && urls && (
                    <>
                      <div className="message-text-center">{text}</div>
                      <div className="message-actions">
                        {urls.transcribeUrl && (
                          <a
                            href={urls.transcribeUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Transcribe Audio
                          </a>
                        )}
                        {urls.analyzeFileUrl && (
                          <a
                            href={urls.analyzeFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Analyze File
                          </a>
                        )}
                        {urls.viewBomAnalysis && (
                          <a
                            href={urls.viewBomAnalysis}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View HTML Report
                          </a>
                        )}
                        {urls.viewSpreadsheetReport && (
                          <a
                            href={urls.viewSpreadsheetReport}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Spreadsheet
                          </a>
                        )}
                        {urls.viewSummary === true && (
                          <a
                            href={urls.viewSummary}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {}}
                          >
                            View Summary
                          </a>
                        )}
                      </div>
                    </>
                  )}

                  {(!urls || sender === "user") &&
                    (format === "list" ? (
                      <ListComponent data={text} />
                    ) : format === "default" ? (
                      <DefaultComponent data={text} />
                    ) : (
                      <div className="message-text">{text}</div>
                    ))}
                </div>
              </div>
            );
          })}
          <div ref={endOfMessagesRef} />
        </div>
      )}
    </Container>
  );
};

export default SharedConversation;
