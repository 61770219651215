// useFileHandler.js is a custom React hook that handles uploading files to the server. It requests a signed URL from a Cloud Function, uploads the file to Google Cloud Storage, and returns the necessary details to the client.
// Files are uploaded to Google Cloud Storage using a signed URL. The signed URL is obtained from a Cloud Function that generates a signed URL for a given file name. The Cloud Function also returns a unique file ID, the blob name, and the file URL. The file ID is used to identify the file in the database, the blob name is used to identify the file in Google Cloud Storage, and the file URL is used to download the file from Google Cloud Storage.


import { useState, useCallback } from 'react';

const useFileHandler = (sendMessage, updateChatMessage) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileDesignation, setFileDesignation] = useState('');
    const [fileAttachedMessage, setFileAttachedMessage] = useState('');
    const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');    
    const [isUploading, setIsUploading] = useState(false);

    // List of allowed file types
    const allowedTypes = [ //26 types
    'application/pdf',                                              // pdf
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'application/msword',                                           // doc
    'image/jpeg',                                                   // jpg, jpeg
    'image/png',                                                    // png
    'text/plain',                                                   // txt
    'application/vnd.ms-excel',                                     // xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'text/csv',                                                     // csv
    'application/vnd.ms-powerpoint',                                // ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'image/gif',                                                    // gif
    'image/bmp',                                                    // bmp
    'audio/mpeg',                                                   // mpeg
    'audio/wav',                                                    // wav
    'audio/x-wav',                                                  // x-wav
    'audio/wave',                                                   // wave
    'audio/aac',                                                    // aac
    'audio/flac',                                                   // flac
    'audio/ogg',                                                    // ogg
    'audio/x-aiff',                                                 // x-aiff
    'audio/m4a',                                                    // m4a
    'audio/x-m4a',                                                  // M4A Audio
    'audio/x-ms-wma',                                               // x-ms-wma
    'audio/vnd.wav',                                                // vnd.wav
    'audio/raw',                                                    // raw
    'image/RAW',                                                    // raw // This is not semantically correct but needed if your system reports it this way
    'application/rtf',                                              // rtf
    
    // Additional types for audio and image files if needed
    ];    
    
    // Define the handleFileSelected function here
    function handleFileSelected(event) {
        console.log("File selected event triggered", event);
    
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            console.error("No file selected or event is not defined properly");
            return;
        }
    
        const file = event.target.files[0];
    
        if (!file) {
            console.error("Selected file is undefined");
            setSelectedFile(null); // Clear the selected file
            setIsSendButtonEnabled(false); // Disable the send button
            setFileAttachedMessage('No file selected.'); // Update the message
            return;
        }
    
        console.log("Selected file:", file);
        console.log("File MIME type:", file.type); // This will log the MIME type
    
        if (file && allowedTypes.includes(file.type)) {
            console.log("File is allowed and selected:", file.name);
            setSelectedFile(file); // Attach the selected file
            setIsSendButtonEnabled(true); // Enable the send button
            console.log("Send button should be enabled now");
            setFileAttachedMessage(`${file.name} ready to send.`);
            console.log("File attached message:", `${file.name} ready to send.`);
        } else {
            // Handle invalid file type 
            console.log("Invalid file type or no file selected");
            setSelectedFile(null); // Clear the selected file
            setIsSendButtonEnabled(false); // Disable the send button
            setFileAttachedMessage('Invalid file type. Allowed types are: ' + allowedTypes.join(', ')); // maybe include: Allowed types
        }
    }    
    
    const handleRemoveFile = () => {
        console.log("file remove function triggered");
        setSelectedFile(null);
        setIsUploading(false);  // If you have logic that updates uploading status
        setUploadMessage('');   // If you have logic that sets an upload message
        setIsSendButtonEnabled(false); // Disable the send button because no file is attached
        console.log("Send button should be disabled now");
        // Additional logic if needed
    };    

    const uploadFile = useCallback(async (fileDesignationParam) => {
        // Use fileDesignationParam within this function instead of fileDesignation from state
        console.log("uploadFile function triggered");

        if (!selectedFile) {
            console.log("No file to upload, exiting uploadFile");
            return;
        }

        const audioTypes = [
            'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/wave', 'audio/aac', 'audio/flac',
            'audio/ogg', 'audio/x-aiff', 'audio/m4a', 'audio/x-m4a', 'audio/x-ms-wma', 'audio/vnd.wav', 'audio/raw', 'image/RAW', // This is not semantically correct but needed if your system reports it this way
        ];
    
        // Send initial 'uploading' message for the user
        const uploadMessageId = sendMessage(`Uploading ${selectedFile.name}...`, 'user');
        setIsUploading(true);
    
        try {
            // Step 1: Request a signed URL from your Cloud Function
            const signedUrlResponse = await fetch(`https://us-central1-maia-tech.cloudfunctions.net/uploadFile/generateSignedUrl?filename=${encodeURIComponent(selectedFile.name)}&fileDesignation=${fileDesignationParam}`);

            if (!signedUrlResponse.ok) {
                throw new Error('Could not obtain signed URL.');
            }
            const signedUrlData = await signedUrlResponse.json();
            console.log("Received signed URL data:", signedUrlData);
            const signedUrl = signedUrlData.signed_url;
    
            // Step 2: Upload the file using the signed URL
            const storageResponse = await fetch(signedUrl, {
                method: 'PUT',
                body: selectedFile,
                headers: {
                    'Content-Type': selectedFile.type,
                },
            });
    
            if (!storageResponse.ok) {
                throw new Error('Upload to Google Cloud Storage failed.');
            }
    
            // Existing logic for handling the response and updating messages
            console.log("Upload successful to Google Cloud Storage");
            updateChatMessage(uploadMessageId, selectedFile.name);
            // Clears any previous error message upon successful upload
            setUploadMessage('');

    
            // Check if the selected file is an audio file
            const isAudioFile = audioTypes.includes(selectedFile.type);

            // Conditionally add transcribeUrl if it's an audio file
            const messageData = isAudioFile ? {
                transcribeUrl: signedUrlData.transcribe_url, // Make sure this matches your actual response structure
             } : {

             };
    
            sendMessage('File uploaded successfully!', 'maia', messageData);
    
            // Construct and return the object with necessary details
            const returnObject = {
                file_id: signedUrlData.file_id, // Adjust according to your response
                file_name: selectedFile.name,
                blob_name: signedUrlData.blob_name, // Adjust according to your response
                file_url: signedUrlData.file_url, // Adjust according to your response
            };
            console.log("Object to be returned:", returnObject);
            return returnObject;
    
        } catch (error) {
            console.error("Error during file upload:", error);
            setUploadMessage('Error during upload. Please try again.');
            updateChatMessage(uploadMessageId, 'Error during upload. Please try again.');
        } finally {
            setIsUploading(false);
            setSelectedFile(null);
            setFileAttachedMessage('');  // Need confirmation this works as intended to reset the file attached message (did not know I needed this)
            setIsSendButtonEnabled(false); // Need confirmation this works as intended Optionally, ensure the send button is disabled after upload        
            setFileDesignation(null); // Clear file designation after upload
        }   

    }, [selectedFile, sendMessage, updateChatMessage, setIsUploading, setSelectedFile, setUploadMessage]); 

    return {
        selectedFile,
        setSelectedFile,
        isSendButtonEnabled,
        setIsSendButtonEnabled,
        handleRemoveFile, 
        isUploading,
        setIsUploading,
        fileAttachedMessage,
        setFileAttachedMessage,
        uploadMessage,
        setUploadMessage,
        handleFileSelected,
        uploadFile, 
        setFileDesignation,      
        // Return all states and functions needed by other components
    };
};

export default useFileHandler;
