// Layout.js - This is the main component in the application (parent of 'Sidebar' and MainContent (and by extension, 'InputSection as part of 'MainContent')), responsible for rendering the entire UI.
// Layout.js manages the overall state regarding document selection, uploaded documents, selected documents and the different modes (Analyze, BOM, SQS, ERP Connect, Record Meeting,) 
// state hooks 'selectedSupplySources' and 'selectedDemandInformation' are passed down as props to the components that require them.
// Child components Sidebar and MainContent receive and use these states and functions as props.
// InputSection then receives them from MainContent.//)

import React, { useState, useEffect} from 'react';
import './Layout.css';
import Sidebar from './Sidebar';
import TopNav from './TopNav';
import MainContent from '../main/MainContent';
import Login from '../login/Login';
import Register from '../registration/Register'; 
import ForgotPassword from '../forgotpassword/ForgotPassword';
import { MessageHistoryProvider } from '../message/MessageHistoryContext';
import { AppProvider } from '../auth/AppContext'; 
import { useAuth } from '../auth/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import ChatHistory from '../chats/ChatHistory';
import { useLayoutState } from './useLayout';

function Layout() {
    const authContext = useAuth();
    console.log("AuthContext in Layout:", authContext); // Check what useAuth returns
    const { currentUser } = useAuth();
    const [isSidebarVisible, setSidebarVisible] = useState(false);  
    const [transcribeUrl, setTranscribeUrl] = useState('');
    const [analyzeFileUrl, setAnalyzeFileUrl] = useState('');
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState({});
    const [selectedSupplySources, setSelectedSupplySources] = useState([]);
    const [selectedDemandInformation, setSelectedDemandInformation] = useState({});
    const [isAnalyzeMode, setIsAnalyzeMode] = useState(false);
    const [isBomMode, setIsBomMode] = useState(false);
    const [isSQSMode, setIsSQSMode] = useState(false);
    const [isErpConnectMode, setIsErpConnectMode] = useState(false);
    const [isRecordMeetingMode, setIsRecordMeetingMode] = useState(false);
    const [overlayModalVisible, setOverlayModalVisible] = useState(false);
    const [supplyMatchingSummaryForSideBar, setSupplyMatchingSummaryForSideBar] = useState([]);
    const [supplySourceFiles, setSupplySourceFiles] = useState([]);
    const [viewMode, setViewMode] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [handleSendClickButton, setHandleSendClickButton] = useState(false);
    const [activeTab, setActiveTab] = useState('defaultTab');
    const { layoutState } = useLayoutState();
    const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(false);
    const [callConversationAPI, setCallConversationAPI] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

    const toggleSidebar = () => {
      setSidebarVisible(prevIsSidebarVisible => !prevIsSidebarVisible);
    };    
    
    const toggleChatHistory = ()=>{
      setIsChatHistoryVisible(prevIsChatHistoryVisible => !prevIsChatHistoryVisible);
    }

    // Function to be called after a successful file upload
    const handleFileUpload = (newDocument) => {
      console.log("New document received in Layout:", newDocument);
      setUploadedDocuments(prevDocs => [...prevDocs, {...newDocument, file_name: newDocument.file_name}]);

    };

    useEffect(() => {
      console.log("uploadedDocuments updated in Layout:", uploadedDocuments);
    }, [uploadedDocuments]);
  

    const handleDocumentSelection = (docId, isSelected) => {
      setSelectedDocuments(prevSelectedDocs => {
        const updatedSelectedDocs = { ...prevSelectedDocs };
        if (isSelected) {
          updatedSelectedDocs[docId] = true;
        } else {
          delete updatedSelectedDocs[docId];
        }
        const isAnyDocumentSelected = Object.keys(updatedSelectedDocs).length > 0;
        setIsAnalyzeMode(isAnyDocumentSelected);
        console.log('Is Analyze Mode:', isAnyDocumentSelected); // Add this line to log the state
        return updatedSelectedDocs;
      });
    };
    
    // Define fetchSupplySourceFiles function
    const fetchSupplySourceFiles = async () => {
      try {
        const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/supplySources');
        if (response.ok) {
          const files = await response.json();

          // Preserve the isChecked state of the current files
          setSupplySourceFiles(prevFiles => {
            const prevFilesCheckedState = prevFiles.reduce((acc, file) => {
                acc[file.name] = file.isChecked;
                return acc;
            }, {});

            // Merge the new files with the preserved isChecked state
            const filesWithChecked = files.map(file => ({
              ...file,
              isChecked: prevFilesCheckedState[file.name] || false,
            }));

            setViewMode('supplySources');
            setIsModalVisible(false); // Make sure the old modal doesn't show
            return filesWithChecked;
          });

        } else {
          console.error('Failed to fetch supply source files');
        }
      } catch (error) {
        console.error('Error fetching supply source files:', error);
      }
    };
    
    const handleSupplySourcesClick = () => {
      // Call fetchSupplySourceFiles to fetch and display the list of supply source files
      fetchSupplySourceFiles();
      setViewMode('supplySources'); // Set viewMode for supply sources
      setActiveTab('supplySources'); // Set the active tab to 'supplySources'
      setOverlayModalVisible(true);  // Assuming this is managed via context or global state
      setIsModalVisible(false);  // Make sure the old modal doesn't show
  
      // Reset other modes
      setIsBomMode(false);
      setIsErpConnectMode(false);
      setIsRecordMeetingMode(false);
    };

    const handleSupplyCheckboxChange = (fileName, isChecked) => {
      console.log(`Checkbox for fileName: ${fileName} is now: ${isChecked}`);
  
      // Update the supplySourceFiles state to reflect the checkbox change
      setSupplySourceFiles(prevFiles =>
        prevFiles.map(file =>
            file.name === fileName ? { ...file, isChecked } : file
        )
      );
    
      // Update the selectedSupplySources state
      setSelectedSupplySources(prevSelected => {
        const updatedSelection = isChecked 
          ? [...prevSelected, { docId: fileName }] 
          : prevSelected.filter(source => source.docId !== fileName);
    
        // Update SQS mode based on the updated selection count
        if (updatedSelection.length > 0) {
          setIsSQSMode(true); // Set SQSMode if any supply source file is selected
          setIsBomMode(false);
          setIsErpConnectMode(false);
          setIsRecordMeetingMode(false);
        } else {
          setIsSQSMode(false); // Reset SQSMode if no files are selected
        }
  
        return updatedSelection;
      });
  };
    
    const [topNavHeight, setTopNavHeight] = useState(0);

    useEffect(() => {
      const calculateTopNavHeight = () => {
        const topNav = document.querySelector('.top-nav');
        setTopNavHeight(topNav?.offsetHeight || 0);
      };

      calculateTopNavHeight(); // Calculate initially
      window.addEventListener('resize', calculateTopNavHeight); // Recalculate on resize

      return () => {
          window.removeEventListener('resize', calculateTopNavHeight); // Clean up
      };
    }, []);

    // Separate function to render based on the user's authentication status
    const renderRoutes = () => {
      if (!currentUser) {
        return (
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> 
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        );
      }
      console.log("CurrentUser in Layout before rendering Sidebar:", currentUser);
      console.log("uploadedDocuments before passing to Sidebar:", uploadedDocuments);

      return (
        <Routes>
          <Route 
            path="/" 
            element={
              <div className="container">
                <TopNav
                  isSidebarVisible={isSidebarVisible}
                  toggleSidebar={toggleSidebar}
                  isChatHistoryVisible={isChatHistoryVisible}
                  toggleChatHistory={toggleChatHistory}
                />
                <Sidebar
                  currentUser={currentUser}
                  isSidebarVisible={isSidebarVisible}
                  toggleSidebar={toggleSidebar}
                  uploadedDocuments={uploadedDocuments}
                  setUploadedDocuments={setUploadedDocuments}
                  selectedSupplySources={selectedSupplySources}
                  setSelectedSupplySources={setSelectedSupplySources}
                  selectedDemandInformation={selectedDemandInformation}
                  setSelectedDemandInformation={setSelectedDemandInformation}                
                  isAnalyzeMode={isAnalyzeMode}
                  setIsAnalyzeMode={setIsAnalyzeMode}
                  isBomMode={isBomMode}
                  setIsBomMode={setIsBomMode}
                  isSQSMode={isSQSMode}
                  setIsSQSMode={setIsSQSMode}  
                  isErpConnectMode={isErpConnectMode}
                  setIsErpConnectMode={setIsErpConnectMode}
                  isRecordMeetingMode={isRecordMeetingMode}
                  setIsRecordMeetingMode={setIsRecordMeetingMode}
                  supplyMatchingSummaryForSideBar={supplyMatchingSummaryForSideBar}
                  handleSendClickButton={handleSendClickButton}
                  setHandleSendClickButton={setHandleSendClickButton}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}             
                  fetchSupplySourceFiles={fetchSupplySourceFiles}
                  handleSupplySourcesClick={handleSupplySourcesClick} 
                  handleSupplyCheckboxChange={handleSupplyCheckboxChange} 
                  supplySourceFiles={supplySourceFiles} 
                  setSupplySourceFiles={setSupplySourceFiles}
                  viewMode={viewMode}
                  setViewMode={setViewMode}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  overlayModalVisible={overlayModalVisible}
                  setOverlayModalVisible={setOverlayModalVisible}
                  callConversationAPI={callConversationAPI}
                />
                <div className="main-content">
                  <MainContent
                    currentUser={currentUser}
                    topNavHeight={topNavHeight}
                    transcribeUrl={transcribeUrl}
                    analyzeFileUrl={analyzeFileUrl}
                    setTranscribeUrl={setTranscribeUrl}
                    setAnalyzeFileUrl={setAnalyzeFileUrl}
                    handleFileUpload={handleFileUpload}
                    selectedSupplySources={selectedSupplySources}
                    setSelectedSupplySources={setSelectedSupplySources}
                    selectedDemandInformation={selectedDemandInformation}  
                    setSelectedDemandInformation={setSelectedDemandInformation}              
                    isAnalyzeMode={isAnalyzeMode}
                    setIsAnalyzeMode={setIsAnalyzeMode}
                    isBomMode={isBomMode}
                    setIsBomMode={setIsBomMode}
                    isSQSMode={isSQSMode}
                    setIsSQSMode={setIsSQSMode}
                    isErpConnectMode={isErpConnectMode}
                    isRecordMeetingMode={isRecordMeetingMode}
                    overlayModalVisible={overlayModalVisible}
                    setOverlayModalVisible={setOverlayModalVisible}
                    setSupplyMatchingSummaryForSideBar={setSupplyMatchingSummaryForSideBar}
                    setHandleSendClickButton={setHandleSendClickButton}
                    handleSendClickButton={handleSendClickButton}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}             
                    fetchSupplySourceFiles={fetchSupplySourceFiles}
                    handleSupplyCheckboxChange={handleSupplyCheckboxChange} // New Line
                    handleSupplySourcesClick={handleSupplySourcesClick} // New Line
                    supplySourceFiles={supplySourceFiles}
                    setSupplySourceFiles={setSupplySourceFiles}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    uploadedDocuments={uploadedDocuments} // New line
                    setUploadedDocuments={setUploadedDocuments} // New line                                      
                    setCallConversationAPI={setCallConversationAPI}
                    callConversationAPI={callConversationAPI}
                    isWaitingForResponse={isWaitingForResponse}
                    setIsWaitingForResponse={setIsWaitingForResponse}
                  />
                </div>
                <div className={`chat-history ${isChatHistoryVisible ? '' : 'hidden'}`}>
                <ChatHistory
                  currentUser={currentUser}
                  isSidebarVisible={isSidebarVisible}
                  toggleSidebar={toggleSidebar}
                  uploadedDocuments={uploadedDocuments}
                  setUploadedDocuments={setUploadedDocuments}
                  isAnalyzeMode={isAnalyzeMode}
                  setIsAnalyzeMode={setIsAnalyzeMode}
                  isBomMode={isBomMode}
                  setIsBomMode={setIsBomMode}
                  toggleChatHistory={toggleChatHistory}
                  isChatHistoryVisible={isChatHistoryVisible}
                  setCallConversationAPI={setCallConversationAPI}
                  callConversationAPI={callConversationAPI}
                  isWaitingForResponse={isWaitingForResponse}
                  setIsWaitingForResponse={setIsWaitingForResponse}
                />
                  {/* <p>Chat History</p> */}
                  {/* ... more chat history content ... */}
                </div>
              </div>
            }
          />
          {/* Add more <Route> components as needed for other paths */}
        </Routes>
      );
    };
      // The main Layout return
  return (
    <AppProvider>
      <MessageHistoryProvider>
        {renderRoutes()}
      </MessageHistoryProvider>
    </AppProvider>
  );
}
export default Layout;
