//LoginLayout.js to handle the toggling between Login and Register views.
// Styling for the layout is done using CSS classes from AuthStyles.css.

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import Register from '../registration/Register';
import ForgotPassword from '../forgotpassword/ForgotPassword'; 
import '../auth/AuthStyles.css'; 

const LoginLayout = () => {
  const location = useLocation();
  const [isLoginView, setIsLoginView] = useState(location.pathname.includes("/login"));
  const isForgotPasswordView = location.pathname.includes("/forgot-password"); 

  useEffect(() => {
    setIsLoginView(location.pathname.includes("/login"));
  }, [location]);

  return (
    <div className="login-layout">
      {/* Left column for logo, text, and hero image */}
      <div className="left-column">
        <div className="logo">
        <img src="https://storage.googleapis.com/webui-assets/Transparent%20Logo%20-%20no%20space.png" alt="Maia Logo"/>
        </div>
        <div id="maia-text">
          Workflow Assistant
        </div>
        <img className="hero-image" src="https://storage.googleapis.com/webui-assets/Maia_Hero_Image.png" alt="Hero" />
      </div>
      {/* Right column for forms */}
      <div className="right-column">
        {/* Duplicate logo and text for small screens */}
        <div className="logo-small-screen">
          <img src="https://storage.googleapis.com/webui-assets/Transparent%20Logo%20-%20no%20space.png" alt="Maia Logo"/>
        </div>
        <div id="maia-text-small-screen">
          Workflow Assistant
        </div>
      
        {/* Navigation and form content */}
        <nav>
          <button onClick={() => setIsLoginView(true)} disabled={isLoginView}>Login</button>
          <button onClick={() => setIsLoginView(false)} disabled={!isLoginView}>Register</button>
        </nav>
        {isForgotPasswordView ? <ForgotPassword /> : (isLoginView ? <Login /> : <Register />)}
      </div>
    </div>
  );
};

export default LoginLayout;
