// Sidebar.js - renders a sidebar that can be shown or hidden, controlled by the 'isSidebarVisible' prop. Provides UI elements like a search bar, a list of features, and a modal 
// for displaying and selecting uploaded documents. Allows users to view and manage uploaded documents and initiate 'analyze mode' by selecting documents for analysis. 

import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../auth/AppContext'; 
import { useAuth } from '../auth/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig'; // Import your Firestore instance
import OverlayModal from '../overlayModal/OverlayModal';  // Import the OverlayModal component
import SettingsModal from '../settingsModal/SettingsModal'; 
import { useNavigate } from 'react-router-dom';
import { faRightFromBracket,faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Divider } from '@mui/material';
import { faSignOutAlt, faCircleUser, faGear, faAddressBook } from '@fortawesome/free-solid-svg-icons';


function Sidebar({ 
  isSidebarVisible, 
  toggleSidebar,
  currentUser,
  uploadedDocuments, 
  setUploadedDocuments,
  selectedSupplySources,
  setSelectedSupplySources,
  //selectedDocuments, (removed as a prop and imported from AppContext instead)
  //setSelectedDocuments, (removed as a prop and imported from AppContext instead)
  isAnalyzeMode, 
  setIsAnalyzeMode,
  isBomMode,
  setIsBomMode,
  isSQSMode,
  setIsSQSMode,
  isErpConnectMode,
  setIsErpConnectMode,
  isRecordMeetingMode,
  setIsRecordMeetingMode,
  supplyMatchingSummaryForSideBar,
  handleSendClickButton,
  setHandleSendClickButton,
  activeTab,
  setActiveTab,
  overlayModalVisible,  
  setOverlayModalVisible,
  fetchSupplySourceFiles,
  handleSupplySourcesClick, 
  handleSupplyCheckboxChange,
  supplySourceFiles,
  setSupplySourceFiles,
  viewMode,
  setViewMode,
  isModalVisible,
  setIsModalVisible

  }) {

  console.log("uploadedDocuments received in Sidebar:", uploadedDocuments);
  const buttonStyle = {
    "borderColor":"lightgrey",
    "color":"black",
    "marginBottom":'10px',
    "width": "100%"
    }

  // State hooks
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInitials, setUserInitials] = useState('');
  const [userName, setUserName] = useState('');
  const { selectedDocuments, setSelectedDocuments } = useContext(AppContext);
  const { logout } = useAuth(); 
  const navigate = useNavigate()
  const sidebarRe = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleProfileClick = () => { 
    setActiveTab('profile'); // Set active tab to 'profile'
    setSettingsModalVisible(true); 
    handleClose();
  };

  const handleContactsClick = () => {
    setActiveTab('contacts'); // Set active tab to 'contacts'
    setSettingsModalVisible(true); 
    handleClose();  
  };

  const handleSettingsClick = () => { 
    setActiveTab('settings'); // Set active tab to 'settings' (if you have a general settings tab)
    setSettingsModalVisible(true); 
    handleClose(); 
  }; 
 
  useEffect(() => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid); // Reference to the user's document in Firestore
  
      getDoc(userDocRef).then(docSnap => {
        if (docSnap.exists()) {
          const userData = docSnap.data();

          console.log("Fetched user data from Firestore:", userData);

          const fullName = `${userData.first_name} ${userData.last_name}`;
          const initials = `${userData.first_name[0]}${userData.last_name[0]}`;
  
          // Log the processed initials and full name
          console.log("Processed initials:", initials);
          console.log("Processed full name:", fullName);

          setUserInitials(initials.toUpperCase());
          setUserName(fullName);
        }
      }).catch(error => {
        console.error("Error fetching user data from Firestore:", error);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("Selected Documents Updated:", selectedDocuments);
    console.log("Is Analyze Mode Active:", isAnalyzeMode);
  }, [selectedDocuments, isAnalyzeMode]);
    
  // Add an additional console.log here to log the structure of each document in uploadedDocuments
  uploadedDocuments.forEach(doc => console.log(`Document: ${doc.id}, File ID: ${doc.file_id}, File Name: ${doc.file_name}`));

  function handleAnalyzeClick() {
    setViewMode('uploadedFiles'); // Ensure viewMode is set to display uploaded documents
    setIsModalVisible(true);
  }
  
  // Handler for checkbox changes in various modal contexts
  const handleCheckboxChange = (docId, isChecked) => {
    console.log(`Checkbox changed: Document ID ${docId}, Checked: ${isChecked}`);
  
    if (viewMode === 'supplySources') {
        console.log(`Handling checkbox change for Supply Sources, FileName: ${docId}`);
        if (isChecked) {
            setSelectedSupplySources(prevSelected => [...prevSelected, docId]);
            console.log(`Supply Source ${docId} added to selection`);
        } else {
            setSelectedSupplySources(prevSelected => prevSelected.filter(name => name !== docId));
            console.log(`Supply Source ${docId} removed from selection`);
        }
    } else if (viewMode === 'uploadedFiles') {
        console.log(`Handling checkbox change for Uploaded Files, Document ID: ${docId}`);
        const updatedSelection = { ...selectedDocuments };
        if (isChecked) {
            const selectedDoc = uploadedDocuments.find(doc => doc.id === docId);
            if (selectedDoc) {
                updatedSelection[docId] = selectedDoc.blob_name; // Store the blob_name
                console.log(`Document ${docId} with blob_name ${selectedDoc.blob_name} added to selection`);
            }
        } else {
            delete updatedSelection[docId];
            console.log(`Document ${docId} removed from selection.`);
        }
        setSelectedDocuments(updatedSelection);
        
        // Update isChecked state in uploadedDocuments
        setUploadedDocuments(prevDocs => prevDocs.map(doc =>
          doc.id === docId ? { ...doc, isChecked } : doc
        ));
        
        console.log('Updated selectedDocuments:', updatedSelection); 
        setIsAnalyzeMode(Object.keys(updatedSelection).length > 0);
        console.log(`Updated selectedDocuments: ${JSON.stringify(updatedSelection)}`);
    }
};

  const handleRemoveDocument = (documentToRemove) => {
    console.log(`Removing document:`, documentToRemove); 
    setUploadedDocuments(currentDocuments =>
      currentDocuments.filter(doc => doc.id !== documentToRemove.id)
    );

    setSelectedDocuments(currentSelected => {
      const newSelected = { ...currentSelected };
      delete newSelected[documentToRemove.id];
      const isAnyDocumentSelected = Object.keys(newSelected).length > 0;
      setIsAnalyzeMode(isAnyDocumentSelected);
      console.log('New selectedDocuments after removal:', newSelected); 
      console.log('Is any document selected for analyze mode:', isAnyDocumentSelected);   
      return newSelected;
    });
  };

  const handleOpenModal = () => {
    setOverlayModalVisible(true);
  };

  // Modify the below function so other buttons can't be selected if ERP Connect button has been selected
  const handleErpConnectClick = () => {
    setIsErpConnectMode(prevMode => !prevMode);
    setIsRecordMeetingMode(false);
    setIsBomMode(false);
    setIsModalVisible(false); // Close the modal when ERP Connect is clicked
  };
  // Modify the below function so other buttons can't be selected if Record Meeting button has been selected
  const handleRecordMeetingClick = () => {
    setIsRecordMeetingMode(prevMode => !prevMode);
    setIsErpConnectMode(false);
    setIsBomMode(false);
    setIsModalVisible(false); // Close the modal when Record Meeting is clicked
  };  

  // Modify this function so other ERP Connect and Record Meeting buttons can't be selected if BOM Assistant button has been selected
  const handleBomAssistantClick = () => {
  // Toggle the BOM mode without affecting the analyze mode
    setIsBomMode(prevMode => !prevMode); // Toggle the BOM mode
    setIsErpConnectMode(false);
    setIsRecordMeetingMode(false);
    setIsModalVisible(false); // Close the modal when BOM Assistant is clicked
  };
    
  // Function to toggle the dropdown menu
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
    
  const handleLogout = () => {
    logout();
  };  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ( isSidebarVisible && sidebarRe.current && !sidebarRe.current.contains(event.target)) {
        // if (!settingsModalVisible) {
        //   toggleSidebar();
        // }
        const isClickOnSidebar = sidebarRe.current.contains(event.target);
        const isClickOnMenuItem = event.target.closest('.MuiMenu-root'); // Adjust the selector as needed
        if (!isClickOnSidebar && !isClickOnMenuItem && !settingsModalVisible) {
          toggleSidebar();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarVisible, settingsModalVisible, toggleSidebar]);
  
  const handleCloseSettingsModal = () => {
    setSettingsModalVisible(false);
  };

  return (
    <div ref={sidebarRe} className={`left-sidebar ${isSidebarVisible ? '' : 'hidden'}`}> 
          <FontAwesomeIcon icon={faTimes} className="sidebar-close-button" onClick={toggleSidebar}/>
      <div className="left-sidebar-logo">
        <img src="https://storage.googleapis.com/webui-assets/Transparent%20Logo%20-%20no%20space.png" alt="Maia Logo" id="left-sidebar-logo" />
        <div id="maia-text">Workflow Assistant</div>
      </div>
      <input type="text" className="search-bar" placeholder="Search..." />

      <ul className="feature-list">
      {/*<li>My Documents</li>*/} 
      {/*<li>My Contacts</li>*/}
        {/* Render the ERP Connect button with green outline if isErpConnectMode is true */}
        <li className={`erp-connect-button ${isErpConnectMode ? 'active-mode' : ''}`} onClick={handleErpConnectClick}>
          ERP Connect
        </li>
        {/* Render the Record Meeting button with green outline if isRecordMeeting is true */}
        <li className={`record-meeting-button ${isRecordMeetingMode ? 'active-mode' : ''}`} onClick={handleRecordMeetingClick}>
          Record Meeting
        </li>
        {/* Render the Supply Sources button with green outline if isSQSMode is true */}
        <li className={`supply-matching-button ${isSQSMode ? 'active-mode' : ''}`} onClick={handleSupplySourcesClick}>
          Supply Matching
        </li>
        {/* Render the BOM Assistant button with green outline if isBomMode is true */}
        <li className={`bom-assistant-button ${isBomMode ? 'active-mode' : ''}`} onClick={handleBomAssistantClick}>
          BOM Assistant
        </li>
        {/* Conditionally render the analyze button with green outline if isAnalyzeMode is true */}
        <li className={`analyze-button ${isAnalyzeMode ? 'active-mode' : ''}`} onClick={handleAnalyzeClick}>
          Analyze
        </li>
      </ul>

      {/* Include the OverlayModal outside of any specific feature-list or modal conditional rendering */}
      <OverlayModal
        isVisible={overlayModalVisible}
        onClose={() => setOverlayModalVisible(false)}
        supplySourceFiles={supplySourceFiles}  
        setSupplySourceFiles={setSupplySourceFiles} 
        fetchSupplySourceFiles={fetchSupplySourceFiles}
        handleSupplySourcesClick={handleSupplySourcesClick} 
        handleSupplyCheckboxChange={handleSupplyCheckboxChange}
        isSQSMode={isSQSMode}
        setIsSQSMode={setIsSQSMode}
        selectedSupplySources={selectedSupplySources}
        setSelectedSupplySources={setSelectedSupplySources}
        supplyMatchingSummary={supplyMatchingSummaryForSideBar}
        handleSendClickButton = {handleSendClickButton}
        setHandleSendClickButton = {setHandleSendClickButton}
        selectedDocuments = {selectedDocuments}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        viewMode={viewMode}
        setViewMode={setViewMode}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      {/* Modal for displaying uploaded documents */}
      {isModalVisible && (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={() => setIsModalVisible(false)} aria-label="Close">&times;</button>
                <div className="modal-header">{viewMode === 'uploadedFiles' ? 'Uploaded Files' : 'Supply Sources'}</div>
                <ul>
                    {viewMode === 'uploadedFiles' && uploadedDocuments.length > 0 ? (
                        uploadedDocuments.map((doc) => (
                            <li key={doc.id} className="modal-file-item">
                                <input 
                                    type="checkbox" 
                                    checked={!!doc.isChecked}  // Ensure the checkbox state is controlled by 'isChecked'
                                    onChange={(e) => handleCheckboxChange(doc.id, e.target.checked)}
                                />
                                <span className="file-name">
                                    {doc.file_name}
                                </span>
                                <button className="remove-attachment-btn" onClick={() => handleRemoveDocument(doc)} aria-label="Remove">
                                    &times;
                                </button>
                            </li>
                        ))
                    ) : (
                        <p>No documents found.</p>
                    )}
                </ul>
            </div>
        </div>
    )}
    <div className="user-section">
        <div className="user-avatar">{userInitials}</div>
        <div className="user-name">{userName}</div>
      <div className="user-settings" onClick={handleClick}>
        ...
      </div>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          <div className="">
            <MenuItem onClick={handleProfileClick}>
              <FontAwesomeIcon icon={faCircleUser} style={{ marginRight: "8px" }}/>
              Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleContactsClick}>
              <FontAwesomeIcon icon={faAddressBook} style={{ marginRight: "8px" }} />
              Contacts
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSettingsClick}>
              <FontAwesomeIcon icon={faGear} style={{ marginRight: "8px" }} />
              Settings
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                style={{ marginRight: "8px" }}
              />
              Log out
            </MenuItem>
          </div>
      </Menu>
    </div>
    {settingsModalVisible && ( 
      <SettingsModal 
        isVisible={settingsModalVisible}  
        onClose={handleCloseSettingsModal} 
        currentUser={currentUser} 
        activeTab={activeTab} // Pass active tab to SettingsModal
      /> 
    )}
    </div>
  );
}

export default Sidebar;
