import { useState } from 'react';

export const useLayoutState = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(false);
  const [transcribeUrl, setTranscribeUrl] = useState('');
  const [analyzeFileUrl, setAnalyzeFileUrl] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [isAnalyzeMode, setIsAnalyzeMode] = useState(false);
  const [isBomMode, setIsBomMode] = useState(false);
  const [isErpConnectMode, setIsErpConnectMode] = useState(false);
  const [isRecordMeetingMode, setIsRecordMeetingMode] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [callConversationAPI, setCallConversationAPI] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(prevIsSidebarVisible => !prevIsSidebarVisible);
  };
  
  const toggleChatHistory = ()=>{
    setIsChatHistoryVisible(prevIsChatHistoryVisible => !prevIsChatHistoryVisible);
  }

  const handleFileUpload = (newDocument) => {
    setUploadedDocuments(prevDocs => [...prevDocs, {...newDocument, file_name: newDocument.file_name}]);
  };

  
  const handleDocumentSelection = (docId, isSelected) => {
    setSelectedDocuments(prevSelectedDocs => {
      const updatedSelectedDocs = { ...prevSelectedDocs };
      if (isSelected) {
        updatedSelectedDocs[docId] = true;
      } else {
        delete updatedSelectedDocs[docId];
      }
      const isAnyDocumentSelected = Object.keys(updatedSelectedDocs).length > 0;
      setIsAnalyzeMode(isAnyDocumentSelected);
      return updatedSelectedDocs;
    });
  };

  return {
    layoutState: {
      isSidebarVisible,
      isChatHistoryVisible,
      transcribeUrl,
      analyzeFileUrl,
      uploadedDocuments,
      selectedDocuments,
      isAnalyzeMode,
      isBomMode,
      isErpConnectMode,
      isRecordMeetingMode,
      clearInput,
      callConversationAPI,
      setCallConversationAPI,
      setIsBomMode,
      setUploadedDocuments,
      setTranscribeUrl,
      setAnalyzeFileUrl,
      setUploadedDocuments,
      setClearInput
    },
    toggleSidebar,
    toggleChatHistory,
    handleFileUpload,
    handleDocumentSelection,
  };
};
