// Register.js

import React, { useState } from 'react';
import { auth, db } from '../../FirebaseConfig'; // Import both auth and db
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { Link, useNavigate } from 'react-router-dom'; 
import '../auth/AuthStyles.css';


const Register = () => {
  const navigate = useNavigate(); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
    if (name === 'firstName') setFirstName(value);
    if (name === 'lastName') setLastName(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Reset any previous error

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return; // Stop the function if passwords do not match
    }

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Add user details to Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), {
        first_name: firstName, // Use the field names as they are in your Firestore
        last_name: lastName,
        email: email,
        approved: false // New line
      });

      // Send verification email
      sendEmailVerification(userCredential.user)
        .then(() => {
          // Email sent
          alert("Registration successful! Please check your email to verify your account.");
          setTimeout(() => {
            navigate('/login'); // Redirect to login page after a delay
          }, 3000); // 3000 milliseconds = 3 seconds
        })
        .catch((error) => {
          // Handle any errors here
          setError("Failed to send verification email: " + error.message);
        });

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="register-container">
      <h1>Register</h1>
      {error && <p className="error">{error}</p>} {/* Display any error message */}
      <form onSubmit={handleSubmit}>
                {/* First Name input */}
                <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={handleInputChange}
            placeholder="Enter your first name"
            required
          />
        </div>
        {/* Last Name input */}
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={handleInputChange}
            placeholder="Enter your last name"
            required
          />
        </div>
        {/* Email input */}
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            placeholder="Enter your email..."
            required
          />
        </div>
        {/* Password input */}
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            placeholder="Enter your password..."
            required
          />
        </div>
        {/* Confirm Password input */}
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleInputChange}
            placeholder="Confirm your password..."
            required
          />
        </div>
        {/* Submit button */}
        <button type="submit" className="btn btn-primary">Register</button>
      </form>
      {/* Link to Login screen */}
      <p>
        Already have an account? <Link to="/login">Login here</Link>.
      </p>
    </div>
  );
};

export default Register;
