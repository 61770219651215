// MainContent.js - This is a structural component in the application, responsible for rendering the MainContent area of the chat interface, including 
// the OverlayModal and functions or effects to fetch the data required by the modal, depending on user actions or other triggers in the application.
// It imports and renders the OverlayModal and passes the SupplyMatchingSummaryTab (or other content tabs as your application evolves) as children to the 
// modal based on the application state or user interactions.
// MainContent.js is the component that directly renders both 'Message' and 'InputSection' components.
// MainContent should not be responsible for setting different modes (ErpConnectMode, BomMode, SQSMode, etc.) but rather for passing them down to components 
// that require them, such as InputSection. If any modes need to be toggled or set from within InputSection or any other child component, the corresponding  
// setters (setIsAnalyzeMode, setIsBomMode, setIsSQSMode, setIsErpConnectMode, setIsRecordMeetingMode) should also be passed down from Layout.js through 
// MainContent to those components as props.

import React, { useState, useEffect, useRef, useContext } from 'react';
import OverlayModal from '../overlayModal/OverlayModal';
import SupplyMatchingSummaryTab from '../overlayModal/SupplyMatchingSummaryTab';
import InputSection from './InputSection'; 
import Message from '../message/Message';
import { useMessageHistory } from '../message/MessageHistoryContext'; 
import { AppContext } from '../auth/AppContext'; 


const MainContent = ({ // Accept props
    currentUser,
    topNavHeight, 
    handleFileUpload, 
    isErpConnectMode,     
    isRecordMeetingMode,     
    isAnalyzeMode,
    setIsAnalyzeMode, 
    isBomMode,    
    setIsBomMode, 
    isSQSMode, 
    setIsSQSMode,
    handleSupplyCheckboxChange, 
    selectedSupplySources, 
    setSelectedSupplySources,
    selectedDemandInformation,
    setSelectedDemandInformation,
    //selectedDocuments, (removed as a prop and imported from AppContext instead)
    //setSelectedDocuments, (removed as a prop and imported from AppContext instead)
    uploadedDocuments, 
    setUploadedDocuments,   
    setOverlayModalVisible,
    overlayModalVisible,
    fetchSupplySourceFiles,
    supplySourceFiles,
    setSupplySourceFiles,
    viewMode,
    setViewMode,
    isModalVisible,
    setIsModalVisible,  
    setSupplyMatchingSummaryForSideBar,
    setHandleSendClickButton,
    handleSendClickButton,
    activeTab,
    setActiveTab,
    clearInput,
    setCallConversationAPI,
    isWaitingForResponse, 
    setIsWaitingForResponse
}) => {

    const { messages } = useMessageHistory(); // Access messages from the context
    const { selectedDocuments, setSelectedDocuments } = useContext(AppContext); // Access selectedDocuments from AppContext
    const endOfMessagesRef = useRef(null); // Create a ref for the end of the messages
    const [supplyMatchingSummary, setSupplyMatchingSummary] = useState([]); // Adding state for supplyMatchingSummary
    const [inputText, setInputText] = useState(''); // Add inputText state here
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false); // New Line: State to track if keyboard is open

    // Scroll to the end every time messages change
    useEffect(() => {
       endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Fetch data for the modal
    useEffect(() => {
        fetchDataForModal();
    }, []);  // Consider dependencies if there are any conditions for fetching

    useEffect(() => {
        console.log("Passing to OverlayModal:", supplyMatchingSummary);
        setSupplyMatchingSummaryForSideBar(supplyMatchingSummary)
    }, [supplyMatchingSummary]); // Proper place for the console.log

    // Define fetchDataForModal within MainContent
    const fetchDataForModal = async () => {
        // Implementation as shown above
    };
         
    // This useEffect is to log the state in MainContent just before it's passed to OverlayModal to confirm it's not being altered unexpectedly elsewhere in the component.
    useEffect(() => {
        console.log("Current state of supplyMatchingSummary just before passing to OverlayModal:", supplyMatchingSummary);
        // This will help confirm that the state is not being modified unexpectedly at any point in MainContent
    }, [supplyMatchingSummary]); // Monitoring changes to supplyMatchingSummary
    
    // Log right before passing to OverlayModal
    console.log("Before passing to OverlayModal - setIsWaitingForResponse type:", typeof setIsWaitingForResponse); // Should output 'function'

    // New Effect Hook to Handle Keyboard State
    useEffect(() => {
        const handleResize = () => {
            setIsKeyboardOpen(window.innerHeight < 500); // Adjust threshold based on your layout
        };

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
    <div className={`main-content ${isKeyboardOpen ? 'keyboard-open' : ''}`} style={{ marginTop: `${topNavHeight}px` }}>
        <div className="scrollable-messages">
            <Message currentUser={currentUser} endOfMessagesRef={endOfMessagesRef} isWaitingForResponse={isWaitingForResponse} setOverlayModalVisible={setOverlayModalVisible} setActiveTab={setActiveTab}/>
        </div>
        <InputSection // Pass props to InputSection
            className={isKeyboardOpen ? 'keyboard-open' : ''} // New Line: Add conditional class
            handleFileUpload={handleFileUpload}
            isAnalyzeMode={isAnalyzeMode}
            setIsAnalyzeMode={setIsAnalyzeMode}
            uploadedDocuments={uploadedDocuments} 
            setUploadedDocuments={setUploadedDocuments} 
            isBomMode={isBomMode}
            setIsBomMode={setIsBomMode}  
            isSQSMode={isSQSMode}
            setIsSQSMode={setIsSQSMode}  
            setIsWaitingForResponse={setIsWaitingForResponse} 
            isErpConnectMode={isErpConnectMode}
            isRecordMeetingMode={isRecordMeetingMode}
            selectedSupplySources={selectedSupplySources}
            setSelectedSupplySources={setSelectedSupplySources}
            selectedDemandInformation={selectedDemandInformation}        
            setSelectedDemandInformation={setSelectedDemandInformation}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            setOverlayModalVisible={setOverlayModalVisible} // Pass the setter function for overlayModalVisible
            setSupplyMatchingSummary={setSupplyMatchingSummary} // Pass the setter function for supplyMatchingSummary
            setHandleSendClickButton={setHandleSendClickButton} // Pass the setter function for handleSendClick
            handleSendClickButton={handleSendClickButton}
            supplySourceFiles={supplySourceFiles}    
            setSupplySourceFiles={setSupplySourceFiles}             
            setActiveTab={setActiveTab}
            inputText={inputText} // Pass inputText state
            setInputText={setInputText} // Pass setInputText function
            clearInput={clearInput}
            setCallConversationAPI={setCallConversationAPI}
        />
        <OverlayModal // Pass props to OverlayModal
            isVisible={overlayModalVisible} 
            onClose={() => setOverlayModalVisible(false)}
            handleSupplyCheckboxChange={handleSupplyCheckboxChange}
            selectedSupplySources={selectedSupplySources}
            setSelectedSupplySources={setSelectedSupplySources}
            supplyMatchingSummary={supplyMatchingSummary}
            setIsWaitingForResponse={setIsWaitingForResponse} 
            isWaitingForResponse={isWaitingForResponse}  
            handleSendClickButton={handleSendClickButton} // Pass handleSendClick to OverlayModal
            setHandleSendClickButton={setHandleSendClickButton}
            selectedDocuments = {selectedDocuments}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            fetchSupplySourceFiles={fetchSupplySourceFiles}
            viewMode={viewMode}  
            isModalVisible={isModalVisible}  
            supplySourceFiles={supplySourceFiles}    
            setSupplySourceFiles={setSupplySourceFiles} 
            inputText={inputText} // Pass inputText state to OverlayModal
        />   
    </div>
    );
}

export default MainContent;
