// SupplyMatchingSummaryTab.js - This file defines the SupplyMatchingSummaryTab component, which is specific to the supply matching functionality within the modal. 
// It handles:displaying specific data: It formats and shows data related to supply matching, such as supplier details, part numbers, and quantities.
// User Interaction for Supply Matching: It includes UI elements like checkboxes and buttons that users interact with to select supplies and generate RFQs
// (Request for Quotation).
// Triggering Business Logic: It has buttons or other interactive elements that, when triggered, can execute functions passed down as props. 
// this might include generating and sending RFQs.
// InputSection (the partent of OverlayModal) is managing various states (including states for 'supplyMatchingSummary') and passing them down to OverlayModal 
// then OverlayModal passes the needed states to SupplyMatchingSummaryTab.


import React, { useState, useEffect } from 'react';

const SupplyMatchingSummaryTab = ({ 
    supplyMatchingSummary, // receive supplyMatchingSummary as a prop from OverlayModal
    setRfqEmailData, // receive setRfqEmailData as a prop from OverlayModal
    setIsWaitingForResponse, // New Line - receive setIsWaitingForResponse as a prop from OverlayModal
    isWaitingForResponse,  // New Line - now you can use this to show/hide a loader or disable buttons
    setActiveTab, // Add setActiveTab as a prop
    
}) => {
    const [selectedSuppliersForRFQ, setSelectedSuppliersForRFQ] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        const docIds = supplyMatchingSummary.map(item => item.docId);
        console.log("All docIds:", docIds);
        const uniqueDocIds = new Set(docIds);
        if (uniqueDocIds.size !== docIds.length) {
            console.error("Duplicate docIds detected:", docIds);
        }
    }, [supplyMatchingSummary]);
    
    useEffect(() => {
        console.log("supplyMatchingSummary updated in SupplyMatchingSummaryTab:", supplyMatchingSummary);
        supplyMatchingSummary.forEach((item, index) => {
            console.log(`Item ${index}: docId is ${item.docId}`);
        });
    }, [supplyMatchingSummary]);    

    // Add this useEffect to log the docId of each item for debugging
    useEffect(() => {
        console.log("Final supplyMatchingSummary data for rendering:", supplyMatchingSummary.map(item => item.docId));
    }, [supplyMatchingSummary]);

    useEffect(() => {
        if (isWaitingForResponse) {
            setIsGenerating(true);
        } else {
            setIsGenerating(false);
        }
    }, [isWaitingForResponse]);
    
    const formatNumber = (number) => {
        return number.toLocaleString(); // Converts number to string with commas
    };

    const handleSelectAllChange = (isChecked) => {
        if (isChecked) {
            const allDocIds = supplyMatchingSummary.map(item => item.docId);
            setSelectedSuppliersForRFQ(allDocIds);
        } else {
            setSelectedSuppliersForRFQ([]);
        }
    };    

    const handleCheckboxChange = (docId, isChecked) => {
        setSelectedSuppliersForRFQ(prev => {
            if (isChecked) {
                return [...prev, docId];
            } else {
                return prev.filter(id => id !== docId);
            }
        });
    };    
        
    const handleGenerateRFQ = async () => {
        const selectedItems = supplyMatchingSummary.filter(item => selectedSuppliersForRFQ.includes(item.docId));
        setIsWaitingForResponse(true);  // Indicate loading start
        try {
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/RFQEmailAutomation', {
                method: 'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ matches: selectedItems })
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch RFQ emails: ' + response.statusText);
            }
    
            const responseData = await response.json();
            setRfqEmailData(responseData);  // Update state in OverlayModal with the new emails
            setActiveTab('rfqEmails'); // Change the active tab to RFQEmailsTab
        } catch (error) {
            console.error("Error in generating RFQ:", error);
        } finally {
            setIsWaitingForResponse(false);  // Reset waiting state after the process
        }
    };
     
    console.log("Data in SupplyMatchingSummaryTab:", supplyMatchingSummary);
    return (
        <div className="overlay-modal">
            <h3>Supply Matching Summary ({supplyMatchingSummary.length} Matches)</h3> {/* Display the number of matches */}
            <div className="modal-body">
                <table className="supply-matching-table">
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th>Customer MPN</th>
                            <th>Manufacturer</th>
                            <th>Target</th>
                            <th>Demand QTY</th>
                            <th>Total Opportunity</th>
                            <th>Supplier Price</th>
                            <th>Supplier QTY</th>
                            <th>Profit Potential</th>
                            <th>Source</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={selectedSuppliersForRFQ.length === supplyMatchingSummary.length}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* items below need to match with 'objects' within 'supplyMatchingSummary', which are defined in supplyMatching API */}
                        {supplyMatchingSummary.map((item, index) => ( 
                            <tr key={item.docId || `fallback-${index}`}> {/* More robust fallback key */}
                                <td>{item['Supplier']}</td>
                                <td>{item['Customer MPN']}</td>
                                <td>{item['Customer Manufacturer']}</td>
                                <td>{item['Target']}</td>
                                <td>{formatNumber(item['Demand QTY'])}</td>
                                <td>{item['Total Opportunity']}</td>
                                <td>{item['Supplier Price']}</td>
                                <td>{formatNumber(item['Supplier QTY'])}</td>
                                <td>{item['Profit Potential']}</td>
                                <td>{item['Source']}</td>
                                <td>{item['Contact Name']}</td>
                                <td>{item['Contact Email']}</td>
                                <td>
                                    <input 
                                        type="checkbox"
                                        onChange={(e) => handleCheckboxChange(item.docId, e.target.checked)}
                                        checked={selectedSuppliersForRFQ.includes(item.docId)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="generate-rfq-button-container">
                <button 
                    className="generate-rfq-button" 
                    onClick={() => {
                        setIsWaitingForResponse(true);
                        handleGenerateRFQ();
                    }} 
                    disabled={isWaitingForResponse || selectedSuppliersForRFQ.length === 0}>
                    {isGenerating ? 'Generating RFQs...' : 'Generate RFQ'}
                </button>
            </div>
        </div>
    );
};    

export default SupplyMatchingSummaryTab;
