// Login.js
// Styling for the login form is done using CSS classes from AuthStyles.css.

import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../FirebaseConfig'; // Import auth and db 
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions 
import '../auth/AuthStyles.css'; // CSS file that contains styles for both login and registration

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Reset errors

    try {
      // Use Firebase auth service to login with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Check if email is verified
      if (!userCredential.user.emailVerified) {
        setError('Please verify your email before logging in.');
        return; // Stop the login process
      }
      // Get user document from Firestore # New Line
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid)); 
      if (userDoc.exists() && userDoc.data().approved) { 
        // If the user is approved, navigate to the dashboard or home page 
        navigate('/'); // Redirect to the main screen after successful login
      } else { 
        // If the user is not approved, sign out and show an error 
        await auth.signOut(); 
        setError("Maia access is limited at this time.\nTo requests access, please email support@maiatech.ai."); 
      } 
    
    } catch (error) {
      // Handle errors like wrong password, user not found etc.
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <h1>Welcome Back</h1>
      {error && <p className="error" style={{ whiteSpace: 'pre-line' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            placeholder="Enter your email..."
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            placeholder="Enter your password..."
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
      {/* Forgot Password link */}
      <p>
        <Link to="/forgot-password">Forgot Password?</Link>
      </p>
      {/* Link to Login screen */}
      <p>
        Don't have an account yet? <Link to="/register">Sign up</Link>.
      </p>
    </div>
  );
};

export default Login;
