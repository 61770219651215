import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ShareUrlModal({ open, handleClose, shareUrl }) {
  const [buttonText, setButtonText] = useState("Copy link");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setButtonText("Copied!");
    setTimeout(() => {
      setButtonText("Copy link");
    }, 2000);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      >
        <Box>
          <DialogTitle id="alert-dialog-title">Share Conversation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">{shareUrl}</Typography>
                {/* &nbsp;&nbsp; */}
                <Button
                  onClick={copyToClipboard}
                  disabled={buttonText === "Copied!"}
                  sx={{ textTransform: "none", color: "black" }}
                  variant="outlined"
                >
                  <ContentCopyIcon fontSize="small" /> <b>{buttonText}</b>
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              autoFocus
              variant="outlined"
            >
              Close{" "}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default ShareUrlModal;
