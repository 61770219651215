// MessageHistoryContext.js is a custom React context that contains chat history.
// The addMessage function is managed here and this is where it addes a new message to the 'messages' state. New messages are objects consisting of 'id', 'text', 'sender', and 'urls' properties.
// 'newMessage' object structure assumes that 'text' is a string and 'urls' is an object. If 'messageData' is passed as 'text', its structure (having both 'text' and 'urls' properties) might cause.
// ... an issue and might not align with what 'Message.js' expects. Adjust messageData Handling: Modify addMessage to handle messageData correctly. Instead of stringifying objects, it should check if the passed text parameter
// ... is the expected messageData object and handle it accordingly. Update analyzeBom Call: Ensure that the analyzeBom function is passing the messageData correctly. You might need to adjust the parameters of the addMessage 
// ... call in analyzeBom to align with the expected structure in addMessage.
// Need to make sure that the addMessage function in MessageHistoryContext.js does not stringify the text when it's an object.  If 'text' is intended to be an object, then the addMessage function here could be a problem for rendering in 'message.js' if it stringifies it. 
// Since you're now passing text as a string, this part of the code shouldn't interfere, but it's good to be aware of this behavior.  Not a problem as long as you ensure that the text part of a message is always passed as a strong and any additional data (like URLs) is passed separately. 


import React, { createContext, useState, useContext } from 'react';
import { useAuth } from '../auth/AuthContext';
import { db } from '../../FirebaseConfig';
import { doc } from 'firebase/firestore';
import axios from "axios";

const MessageHistoryContext = createContext();

export const useMessageHistory = () => useContext(MessageHistoryContext);

export const MessageHistoryProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [clearedMessages, setClearedMessages] = useState([]);
    const [conversationId, setConversationId] = useState("");
    const { currentUser } = useAuth();
    const userDocRef = doc(db, "users", currentUser?.uid ?? '0');
  
    const addMessage = (text, sender, urls, actions = [], id = Date.now()) => {
        // Ensure actions is always an array, even if improperly set
        actions = Array.isArray(actions) ? actions : [];

        // Check if text is an object and convert to string if necessary
        console.log(`Before conversion, type of text: ${typeof text}`, text); // Log before conversion
        if (typeof text === 'object' && text !== null) {
            console.error("Received object instead of string in addMessage. Converting to string.");
            text = JSON.stringify(text, null, 2); // Converts the object to a readable string
        } else {
            // #New Line - Handle the case where text is already a string
            console.log("Adding text message to the history");
        }
        console.log("Current messages before adding new:", messages);
        const newMessage = { id, text, sender, urls, actions };
        console.log(`Adding message: ${text}`); // Log the message being added

        const currentConversationId = localStorage.getItem(
            "current_conversation_id"
          );

          axios
          .post(
            "https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/saveConversationMessages",
            {
              userId: userDocRef.id,
              conversationId: currentConversationId,
              messages: [newMessage],
            }
          )
          .then((response) => {
            localStorage.setItem(
              "current_conversation_id",
              response.data.conversationId
            );
            console.log(
              "API r.conversationIdesponse:",
              response.data.conversationId
            );
          })
          .catch((error) => {
            console.error("API error:", error);
          });

        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, newMessage];
            console.log("Messages after add:", updatedMessages); // Log the new state after adding a message
            return updatedMessages;
        });
    };

    const updateMessage = (id, newText, newUrls) => {
        console.log(`Updating message ID ${id} to: ${newText}`); // Log the message being updated
        setMessages(prevMessages => {
            console.log(`Updating message with ID ${id} to new text: ${newText}`);
            const updatedMessages = prevMessages.map(msg =>
                msg.id === id ? { ...msg, text: newText, urls: newUrls || msg.urls, actions: msg.actions} : msg
            );
            console.log("Messages after update:", updatedMessages); // Log the new state after updating a message
            return updatedMessages;
        });
    };

    const setMessagesFromConversation = (data) => {

        if (Array.isArray(data)) {
          const newConversationId = data[0]?.conversation_id;
    
          if (newConversationId) {
            setConversationId(newConversationId);
            const filteredMessages = messages.filter(
              (msg) => msg.conversation_id !== newConversationId
            );
    
            const conversationData = data.map((conversation) => {
              return {
                conversation_id: conversation.conversation_id,
                messages: conversation.messages || [],
              };
            });
    
            setMessages((prevMessages) => {
              const updatedMessages = [
                // ...filteredMessages,
                ...conversationData.reduce((acc, conversationItem) => {
                  if (Array.isArray(conversationItem.messages)) {
                    acc.push(
                      ...conversationItem.messages.map((messageItem) => {
                        const { id, text, sender, urls } = messageItem;
                        return {
                          id,
                          text,
                          sender,
                          urls
                        };
                      })
                    );
                  }
                  return acc;
                }, []),
              ];
              return updatedMessages;
            });
          } else {
            console.error("Invalid data structure:", data);
          }
        }
      };

    const clearMessages = () => {
        setClearedMessages(messages); // Save the messages before clearing
        setMessages([]);
      };    

    return (
      <MessageHistoryContext.Provider
        value={{
          messages,
          addMessage,
          updateMessage,
          clearMessages,
          clearedMessages,
          setMessagesFromConversation,
        }}
      >
        {children}
      </MessageHistoryContext.Provider>
    );
};
