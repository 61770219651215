// Message.js - the 'parent component' that orchestrates the rendering of the UI. Uses 'useMessageHistory' hook from 'MessagHistoryContext' to access the current state of chat messages and displays them in the UI.
// 'Message.js' does not modify the message state, it only presents the messages to the user. 

import React, { useEffect, useState } from 'react';
import { useMessageHistory } from './MessageHistoryContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';

// Basic implementation of ListComponent
const ListComponent = ({ data }) => {
    return <div style={{ whiteSpace: 'pre-line' }}>{data}</div>; // Preserves line breaks and spacing
};

// Basic implementation of DefaultComponent
const DefaultComponent = ({ data }) => {
    return <div>{data}</div>;
};

// Define the determineFormat function within Message.js
const determineFormat = (text) => {
    // Implement the logic to determine the format of the message
    if (text.includes('\n') && text.includes(':')) {
        return 'list';
    }
    return 'default';
};

const Message = ({ currentUser, endOfMessagesRef, isWaitingForResponse, setOverlayModalVisible, setActiveTab }) => {
    const { messages } = useMessageHistory();
    const [userInitials, setUserInitials] = useState('');
    const [userName, setUserName] = useState('');
    // const [inputText, setInputText] = useState('');
    console.log("Messages in Message component:", messages); // Log the messages array

    useEffect(() => {
        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid); // Reference to the user's document in Firestore
          getDoc(userDocRef).then(docSnap => {
            if (docSnap.exists()) {
              const userData = docSnap.data();
              const fullName = `${userData.first_name} ${userData.last_name}`;
              const initials = `${userData.first_name[0]}${userData.last_name[0]}`;
              setUserInitials(initials.toUpperCase());
              setUserName(fullName);
            }
          }).catch(error => {
            console.error("Error fetching user data from Firestore:", error);
          });
        }
      }, [currentUser]);

    return (
        <div>
            {messages.map(({ id, text, sender, urls, actions }) => {
                actions = Array.isArray(actions) ? actions : [];  // Ensure actions is always an array
                const format = determineFormat(text);
    
                return (
                    <div key={id} className={`message-container ${sender}`}>
                        {sender === 'maia' && (
                            <div className="message-logo">
                                <img src="https://storage.googleapis.com/webui-assets/M_Logo-transparent.png" alt="Maia Logo" />
                            </div>
                        )}
                
                        {sender === 'user' && (
                            <div className="message-avatar">{userInitials}</div>
                        )}
                
                        <div className="message-content">
                            {/* Maia's messages with URLs (special cases) */}
                            {sender === 'maia' && urls && (
                                <>
                                    {/* Center the text */}
                                    <div className="message-text-center">{text}</div>
                                    {/* Render the action buttons */}
                                    <div className="message-actions">
                                        {urls.transcribeUrl && (
                                            <a href={urls.transcribeUrl} target="_blank" rel="noopener noreferrer">Transcribe Audio</a>
                                        )}
                                        {urls.analyzeFileUrl && (
                                            <a href={urls.analyzeFileUrl} target="_blank" rel="noopener noreferrer">Analyze File</a>
                                        )}
                                        {urls.viewBomAnalysis && (
                                            <a href={urls.viewBomAnalysis} target="_blank" rel="noopener noreferrer">View HTML Report</a>
                                        )}
                                        {urls.viewSpreadsheetReport && (
                                            <a href={urls.viewSpreadsheetReport} target="_blank" rel="noopener noreferrer">View Spreadsheet</a>
                                        )}
                                        {urls.viewSummary === true && (
                                            <a href={urls.viewSummary} target="_blank" rel="noopener noreferrer" onClick={()=>{setOverlayModalVisible(true);
                                                setActiveTab('supplyMatchingSummary');}}>View Summary</a>
                                        )}
                                        {/* {actions.map((action, index) => (
                                            <button key={index} className="message-button" onClick={() => {
                                                action.action(); // This is where the function associated with the button is called
                                            }}>
                                                {action.label}
                                            </button>
                                        ))} */}
                                    </div>
                                </>
                            )}

                            {/* Maia's normal chat responses or user messages */}
                            {(!urls || sender === 'user') && (
                                format === 'list' ? <ListComponent data={text} /> : 
                                format === 'default' ? <DefaultComponent data={text} /> : 
                                <div className="message-text">{text}</div>
                            )}
                        </div>
                    </div>
                );
            })}

            {isWaitingForResponse && (
                <div className="message-container maia">
                    <div className="message-logo">
                        <img src="https://storage.googleapis.com/webui-assets/M_Logo-transparent.png" alt="Loading..." />
                    </div>
                    <div className="message-content">
                        <div className="loading-container">
                            <span>Loading...</span>
                            <div className="loading-indicator"></div>
                        </div>
                    </div>
                </div>
            )}
        <div ref={endOfMessagesRef} />
    </div>
)};

export default Message;