// components/settingsModal/ContactForm.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  IconButton,
  ArrowBackIcon,
  Typography,
  InputAdornment,
  Visibility,
  VisibilityOff,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../MuiComponents'; // Ensure Dialog components are exported in MuiComponents.js

const ContactForm = ({ contact, onSave, onCancel, onDelete, loading }) => { 
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [pin, setPin] = useState('');
  const [notes, setNotes] = useState('');

  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // New state for delete dialog

  useEffect(() => {
    if (contact) {
      setContactName(contact.contact_name || '');
      setContactPhone(contact.contact_phone || '');
      setAccountNumber(contact.account_number || '');
      setLoginEmail(contact.login_email || '');
      setLoginPassword(contact.login_password || '');
      setPin(contact.pin || '');
      setNotes(contact.notes || '');
    }
  }, [contact]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newContact = {
      contact_name: contactName,
      contact_phone: contactPhone,
      account_number: accountNumber,
      login_email: loginEmail,
      login_password: loginPassword,
      pin: pin,
      notes: notes,
    };
    onSave(newContact);
  };
  
  const handleDelete = () => {
    onDelete(contact.id); // Pass the contact ID to the onDelete handler
  };

  return (
    <form onSubmit={handleSubmit}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "10px",
      }}
      onClick={onCancel}
    >
      <ArrowBackIcon />
      <Typography variant="subtitle1" color="primary" style={{ marginLeft: "5px" }}>
        Back to Contacts
      </Typography>
    </div>
      <TextField
        label="Contact Name"
        value={contactName}
        onChange={(e) => setContactName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Contact Phone Number"
        value={contactPhone}
        onChange={(e) => setContactPhone(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Account Number"
        type={showAccountNumber ? 'text' : 'password'} 
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle account number visibility"
                onClick={() => setShowAccountNumber(!showAccountNumber)} 
                edge="end"
              >
                {showAccountNumber ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Login Email Address"
        value={loginEmail}
        onChange={(e) => setLoginEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Login Password"
        type={showPassword ? 'text' : 'password'} 
        value={loginPassword}
        onChange={(e) => setLoginPassword(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)} 
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Pin"
        type={showPin ? 'text' : 'password'} 
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle pin visibility"
                onClick={() => setShowPin(!showPin)} 
                edge="end"
              >
                {showPin ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />
      {/* Save and Cancel Buttons */}
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Save Contact
      </Button>
      <Button
        type="button"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={onCancel}
        style={{ marginTop: '10px' }}
      >
        Cancel
      </Button>

      {/* Delete Contact Option */}
      <Button
        type="button"
        variant="text"
        color="error"
        fullWidth
        onClick={() => setDeleteDialogOpen(true)} // Open delete confirmation dialog
        style={{ marginTop: '20px' }}
      >
        Delete Contact
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)} // Close the dialog
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
          >
            Delete Contact
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default ContactForm;
