import React, { useEffect } from 'react'; 

const RFQEmailsTab = ({ 
    rfqEmailData, 
    handleEmailChanges, 
    sendEmail, 
    deleteEmail, 
    clearAllEmails,
    isSendingAll, 
    sendingEmails, 
    sentEmailIndices, 
    setResetSuccess,
    isDeletingAll,  // New state prop for Delete All button  
    deletingEmails,  // New state prop for individual Delete buttons
    deletedEmailIndices  // New prop

    
}) => {
    const emailCount = rfqEmailData ? rfqEmailData.length : 0;

    const sendAllEmails = () => {
        rfqEmailData.forEach((email, index) => sendEmail(email, index));
    };

    const deleteAllEmails = async () => {
        await clearAllEmails();
    };

    const handleDeleteEmail = async (index) => {
        await deleteEmail(index);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component is rendered
    }, []);

    useEffect(() => {
        if (sentEmailIndices.length > 0) {
            setTimeout(() => setResetSuccess(true), 3000);  // Reset success messages after 3 seconds
        }
    }, [sentEmailIndices, setResetSuccess]);

    return (
        <div className="rfq-emails-tab">
            <div className="top-buttons-container">
                <h3>RFQ Emails ({emailCount} Generated)</h3> {/* Dynamic display of the number of emails generated */}
                <div className="buttons">
                    <button onClick={sendAllEmails} className="send-all-button" disabled={isSendingAll}>
                        {isSendingAll ? 'Sending All...' : 'Send All'}
                    </button>
                    <button onClick={deleteAllEmails} className="delete-all-button" disabled={isDeletingAll || isSendingAll}>
                        {isDeletingAll ? 'Deleting All...' : 'Delete All'}
                    </button>
                </div>
            </div>
            {rfqEmailData && rfqEmailData.length > 0 ? (
                rfqEmailData.map((email, index) => (
                    <div key={index} className="email-container">
                        {sentEmailIndices.includes(index) ? (
                            <div className="success-message">Email sent successfully!</div>
                        ) : deletedEmailIndices.includes(index) ? (
                            <div className="success-message">Email successfully deleted!</div>                        
                        ) : (
                            <>
                                <input
                                    type="email"
                                    value={email.to}
                                    onChange={(e) => handleEmailChanges(index, { ...email, to: e.target.value })}
                                    placeholder="Recipient's email"
                                    className="rfq-email-to-input"
                                />
                                <div className="vendor-name">Supplier: {email.vendor}</div> {/* Display vendor name */}
                                <textarea
                                    className="rfq-email-textarea"
                                    value={email.content}
                                    onChange={(e) => handleEmailChanges(index, { content: e.target.value })}  // New Line
                                />
                                <button onClick={() => sendEmail(email, index)}
                                    className="send-email-button" disabled={sendingEmails.includes(index)}>
                                    {sendingEmails.includes(index) ? 'Sending...' : 'Send Email'}
                                </button>
                                <button onClick={() => handleDeleteEmail(index)}
                                    className="delete-email-button" disabled={sendingEmails.includes(index) || deletingEmails.includes(index)}>
                                    {deletingEmails.includes(index) ? 'Deleting...' : 'Delete Email'}
                                </button>
                            </>
                        )}
                    </div>
                ))
            ) : (
                <div>No RFQ emails generated at this time.</div>
            )}
        </div>
    );
};

export default RFQEmailsTab;
