import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';
import axios from 'axios';  // Import Axios for API calls
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Menu, 
  MenuItem, 
  MoreVertIcon, 
  AddIcon, 
  Button,
  Dialog,  // Dialog for pop-up window to manage call
  DialogActions,  
  DialogContent,  
  DialogContentText,
  DialogTitle,  
  EditIcon,    
  CallIcon,    
  EmailIcon     
} from '../MuiComponents'; 
import ContactForm from './ContactForm'; // Importing the ContactForm component

const Contacts = ({ currentUser, userPhoneNumber, onClose }) => {
  const [contacts, setContacts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [callDialogOpen, setCallDialogOpen] = useState(false);  

  useEffect(() => {
    if (currentUser) {
      const fetchContacts = async () => {
        const contactsRef = collection(db, "users", currentUser.uid, "contacts");
        const querySnapshot = await getDocs(contactsRef);
        setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      };

      fetchContacts();
    }
  }, [currentUser]);

  const handleMenuOpen = (event, contact) => {
    event.preventDefault(); // Prevent default behavior that might interfere
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget); // Set anchorEl to the button being clicked
      setSelectedContact(contact);
      console.log("anchorEl set to:", event.currentTarget);  // Log the element to check validity
    } else {
      console.error("Invalid anchorEl:", event.currentTarget);
    }
  };  

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddContact = () => {
    setSelectedContact(null);  // Clear the selection when adding a new contact
    setShowForm(true);
  };

  const handleEdit = () => {
    setShowForm(true);  // Show the form for editing
    handleMenuClose(); // Close the menu after setting the contact for editing
  };

  const handleBackToList = () => {
    setShowForm(false);  // Return to the contact list
  };

  const handleSaveContact = async (newContact) => {
    setLoading(true);
    try {
      const contactsRef = collection(db, "users", currentUser.uid, "contacts");

      if (selectedContact && selectedContact.id) { // If editing, update the existing contact
        const contactDocRef = doc(contactsRef, selectedContact.id);
        await updateDoc(contactDocRef, newContact);
      } else {
        const docRef = await addDoc(contactsRef, newContact); // If adding a new contact
        newContact.id = docRef.id; // New Line: Capture the generated document ID and store it as `contact_id`
      }

      // Refresh the contact list after saving
      const querySnapshot = await getDocs(contactsRef);
      setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setShowForm(false);
    } catch (error) {
      console.error("Error saving contact: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContact = async (contactId) => {
    if (currentUser && contactId) {
      setLoading(true); // Set loading state to true
      try {
        const contactsRef = collection(db, "users", currentUser.uid, "contacts");
        const contactDocRef = doc(contactsRef, contactId);
        await deleteDoc(contactDocRef); // Delete the contact from Firestore
  
        // Refresh the contact list after deletion
        const querySnapshot = await getDocs(contactsRef);
        setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setShowForm(false); // Go back to the contact list after deletion
      } catch (error) {
        console.error("Error deleting contact: ", error);
      } finally {
        setLoading(false); // Set loading state back to false
      }
    }
  };  

  const handleCall = async (event) => {  
    event.preventDefault(); // Prevent default behavior that might cause the menu to shift
    if (currentUser && selectedContact) {
      console.log("Call initiated"); // Log to ensure the function is triggered
      console.log("Using userPhoneNumber passed as prop:", userPhoneNumber);

      const payload = {
        uid: currentUser.uid,
        contact_phone: selectedContact.contact_phone,
        user_phone_number: userPhoneNumber,  
        contact_id: selectedContact.id
      };
  
      console.log("Payload being sent to API:", payload); // Log the payload
      console.log("User Phone Number in payload:", payload.user_phone_number);
      console.log("Current User Object:", currentUser);
      console.log("Available keys in currentUser:", Object.keys(currentUser));
        
      try {
        // Close the menu before opening the dialog
        setAnchorEl(null); // Close the menu

        // Introduce a short delay before opening the dialog
        setTimeout(async () => {
          await axios.post('https://us-central1-maia-tech.cloudfunctions.net/callContact_TwilioConf', payload);
          setCallDialogOpen(true); 
        }, 100); // 100ms delay
      } catch (error) {
        console.error("Error making call:", error);
      }
    }
  };

  const handleStopMaia = async () => {  
    if (currentUser) {
      try {
        await axios.post('https://us-central1-maia-tech.cloudfunctions.net/end_TwilioConf', {
          uid: currentUser.uid,
          endCall: false
        });
      } catch (error) {
        console.error("Error stopping Maia: ", error);
      }
    }
  };

  const handleEndCall = async () => {  
    if (currentUser) {
      try {
        console.log("Sending end call request..."); // Log before API call
        await axios.post('https://us-central1-maia-tech.cloudfunctions.net/end_TwilioConf', {
          uid: currentUser.uid,
          endCall: true
        });

        console.log("End call request successful, attempting to close dialog...");
      } catch (error) {
          console.error("Error ending call:", error);
      } finally {
          // Always attempt to close the dialog, regardless of error
          setCallDialogOpen(false);
          setAnchorEl(null); // Close the menu
      }
    }
  };

  return (
    <div>
      {showForm ? (
        <div>
          <ContactForm
            contact={selectedContact}  
            onSave={handleSaveContact}
            onBack={handleBackToList}  
            onCancel={handleBackToList}
            onDelete={handleDeleteContact} 
            loading={loading}
          />
        </div>
      ) : (
        <div>
          <List>
            {contacts.map((contact) => (
              <ListItem key={contact.id}>
                <ListItemText primary={contact.contact_name} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={(event) => handleMenuOpen(event, contact)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEdit}>
                <EditIcon /> Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleCall(event)}>
                <CallIcon /> Call
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <EmailIcon /> Email
              </MenuItem>
            </Menu>
          </List>
          <Button variant="contained" color="primary" onClick={handleAddContact} startIcon={<AddIcon />}>
            Add Contact
          </Button>
          {/* Call Management Dialog */}
          <Dialog
            open={callDialogOpen}
            onClose={() => setCallDialogOpen(false)}
          >
            <DialogTitle>Manage Call</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are in a call with {selectedContact?.contact_name}. What would you like to do?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStopMaia} color="primary">
                Stop Maia
              </Button>
              <Button onClick={handleEndCall} color="error">
                End Call
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Contacts;
