// FirebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDt5gGodchGWc6a6Y83h25VU84c1dvbKlc",
  authDomain: "maia-tech.firebaseapp.com",
  projectId: "maia-tech",
  storageBucket: "maia-tech.appspot.com",
  messagingSenderId: "370081006843",
  appId: "1:370081006843:web:5fc5cdba10794ded5b9c5e",
  measurementId: "G-VN4481RZ8V"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

export { auth, db }; // Export both auth and db

