// InputSection.js - handles the user input section of the chat window, such as text entries and file uploads. Uses the functions from `ChatLogic.js` (`sendMessage` and `updateChatMessage`)
// to send new messages to the chat or update existing messages.
// It uses several React hooks for managing state and effects, and interacts with the following key external files and contexts: 
// ChatLogic.js, useFileHandler.js, MessageHistoryContext.js, AppContext.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperclip, faPaperPlane, faArrowUp, faMicrophoneSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useChatLogic from '../chats/ChatLogic'; 
import useFileHandler from './useFileHandler';
import { useMessageHistory } from '../message/MessageHistoryContext'; 
import { AppContext } from '../auth/AppContext'; 
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function InputSection({ // receive these functions from props 
    handleFileUpload, 
    isErpConnectMode, 
    isRecordMeetingMode, 
    isAnalyzeMode,
    setIsAnalyzeMode, 
    isBomMode, 
    setIsBomMode,
    isSQSMode, 
    setIsSQSMode,
    fileAttachedMessage, 
    setIsWaitingForResponse,
    selectedSupplySources, 
    setSelectedSupplySources,
    selectedDemandInformation,
    setSelectedDemandInformation,
    setSupplyMatchingSummary, 
    setHandleSendClick,
    handleSendClickButton,
    setHandleSendClickButton,
    setOverlayModalVisible,
    setActiveTab,
    supplySourceFiles,    
    setSupplySourceFiles,
    uploadedDocuments, // New line
    setUploadedDocuments, // New line
    //selectedDocuments, (removed as a prop and imported from AppContext instead)
    //setSelectedDocuments, (removed as a prop and imported from AppContext instead)
    inputText, // Receive inputText as prop
    setInputText, // Receive setInputText as prop
    clearInput: initialClearInput,
    setCallConversationAPI
}) { 
   
// Utility to extract document file names from 'Uploaded Files' (adjusted to handle strings directly instead of expecting an object)
//const extractDocumentDetails = (documents) => {
//    console.log("Initial Documents State:", documents);  // Log the complete document state
//    return Object.values(documents)
//        .filter(fileName => typeof fileName === 'string' && fileName.trim() !== '') // Ensure it's a non-empty string
//        .map(fileName => fileName); // Directly use the string
//};
    const { selectedDocuments, setSelectedDocuments } = useContext(AppContext);
    const { sendMessage, updateChatMessage } = useChatLogic(); // Use the custom hook from ChatLogic.js
    
    // Custom hooks for file handling   
    const { 
        selectedFile, 
        handleFileSelected, 
        uploadMessage, 
        uploadFile,
        handleRemoveFile,
        isSendButtonEnabled,
        setIsSendButtonEnabled,
    } = useFileHandler(sendMessage, updateChatMessage); // Use the custom hook from useFileHandler.js

    //const [inputText, setInputText] = useState(''); defined this in MainContent and passed it here, so could then pass it to OverlayModal and SupplySourcesTab
    const fileInputRef = useRef(null);
    const textFieldRef = useRef(null);

    const [placeholderText, setPlaceholderText] = useState('');
    const [showStyledPlaceholder, setShowStyledPlaceholder] = useState(false); 
    const [textareaStyle, setTextareaStyle] = useState({});
    const [textFieldClass, setTextFieldClass] = useState('');
    const [isSupplySource, setIsSupplySource] = useState(false);
    const [userDemandData, setUserDemandData] = useState(''); // Add this line
    const [transcriptText, setTranscriptText] = useState("");
    const [isPending, setIsPending] = useState(false);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
      } = useSpeechRecognition();
    
      // Update transcriptText whenever transcript changes
      useEffect(() => {
        setTranscriptText(transcript);
      }, [transcript]);

      
      const Colors = {
        color: "#1490af",
      };

    useEffect(() => {
      if (handleSendClickButton == true) {
        handleSendClick()
        setHandleSendClickButton(false);  // Reset the button state
      }
    }, [handleSendClickButton, setHandleSendClickButton])

    // Effect for text field height adjustment
    useEffect(() => {
        const textField = textFieldRef.current;
        const adjustHeight = () => {
            if (textField) {
                textField.style.height = 'auto';
                textField.style.height = `${textField.scrollHeight}px`;
            }
        };

        // Add event listener
        textField.addEventListener('input', adjustHeight);

        // Initial adjustment
        adjustHeight();

        // Clean up
        return () => {
            textField.removeEventListener('input', adjustHeight);
        };
    }, []);

    // useEffect for updating isSendButtonEnabled
    useEffect(() => {
        const isTextPresent = inputText.trim().length > 0;
        const isFileSelected = selectedFile != null;
        const isBothAnalyzeAndBomMode = isAnalyzeMode && isBomMode;
        const isBothAnalyzeAndSQSMode = isAnalyzeMode && isSQSMode;


        setIsSendButtonEnabled(isTextPresent || isFileSelected || isBothAnalyzeAndBomMode || isBothAnalyzeAndSQSMode || isErpConnectMode || isRecordMeetingMode);
    }, [inputText, selectedFile, isAnalyzeMode, isBomMode,isSQSMode, isErpConnectMode, isRecordMeetingMode]);

    // Logging effects
    useEffect(() => {
        console.log("InputSection mounted. Initial mode values:", "isAnalyzeMode:", isAnalyzeMode, "isBomMode:", isBomMode, "isSQSMode:", isSQSMode, "isErpConnectMode:", isErpConnectMode, "isRecordMeetingMode:", isRecordMeetingMode);
    }, []);

    useEffect(() => {
        console.log("Mode values updated:", "isAnalyzeMode:", isAnalyzeMode, "isBomMode:", isBomMode);
    }, [isAnalyzeMode, isBomMode]);
    
    const { messages, addMessage } = useMessageHistory(); // Get the message state and addMessage function from your context

    // Function to handle the paperclip icon click
    const handlePaperclipClick = () => {
        //console.log("Paperclip icon clicked");
        fileInputRef.current.click(); // Opens the file dialog
    };
    
    // Define the handleTextInputChange function, which is responsible for handling text that is input in the text field
    function handleTextInputChange(event) {
        setInputText(event.target.value);
        const textInput = event.target.value;
        const isTextPresent = textInput.trim().length > 0;
        const isFileSelected = selectedFile != null

        console.log("Text present:", isTextPresent, "File selected:", isFileSelected, "Analyze and BOM Mode:", isAnalyzeMode && isBomMode);
        
        setIsSendButtonEnabled(isTextPresent || isFileSelected || (isAnalyzeMode && isBomMode));   
        setUserDemandData(textInput); // Add this line to update userDemandData
    }   

    // Function to call when the text area is focused
    const handleFocus = () => {
        setShowStyledPlaceholder(false); // Hide the styled placeholder
    };

    // Function to call when the text area loses focus
    const handleBlur = (e) => { // Pass the event object e
        if (selectedFile && !e.target.value) { // Use the passed event object
           setShowStyledPlaceholder(true); // Show the styled placeholder only if there's no text
        }
    };

    // Function responsible for handling file uploads when they have a designation attached to them (e.g., files designated as 'Supply Source' files) 
    const handleUploadWithDesignation = async () => {
        const currentDesignation = isSupplySource ? 'supplySource' : 'regularUpload';
        const uploadResult = await uploadFile(currentDesignation);
        // Handle the upload result as needed
        setIsSupplySource(false); // Optionally reset the isSupplySource state here if needed
        return uploadResult;
    };

    const handleSendClick = async () => {
       let demandInformationInput = "";

        console.log(`handleSendClick - Input Text: ${inputText}`);
        // Only add user's message to the chat if inputText is not empty
        if (inputText.trim() !== '') {
            addMessage(inputText, 'user'); // Add user's message to the chat immediately
            setInputText(''); // Reset input text
            setTranscriptText("");
        }
        setIsPending(true);
        setIsSendButtonEnabled(false);
        setIsWaitingForResponse(true); // Set loading state to true when sending a message

        console.log(`handleSendClick - isAnalyzeMode: ${isAnalyzeMode}, isBomMode: ${isBomMode}, isSQSMode: ${isSQSMode}, isErpConnectMode: ${isErpConnectMode}, isRecordMeetingMode: ${isRecordMeetingMode}, selectedDocuments: ${JSON.stringify(selectedDocuments)}`);
        
        setSupplySourceFiles(prevFiles =>
            prevFiles.map(file => ({ ...file, isChecked: false }))
        );

        // Reset the text field height after submission
        if (textFieldRef.current) {
            textFieldRef.current.style.height = 'auto'; // or the initial height value you prefer
        }

        if (selectedFile) {
            // Call handleUploadWithDesignation instead of uploadFile directly
            const uploadedDocumentDetails = await handleUploadWithDesignation();    
            
            if (uploadedDocumentDetails) {
                const newDocument = {
                    id: uploadedDocumentDetails.file_id,
                    file_id: uploadedDocumentDetails.file_id,
                    file_name: uploadedDocumentDetails.file_name,
                    blob_name: uploadedDocumentDetails.blob_name
                };
    
                console.log("New document to be added:", newDocument);   
                handleFileUpload(newDocument); // Updates the uploadedDocuments state with the new document
            } else {
                // Handle the case where the file upload didn't return details (e.g., upload failed)
                console.log("File upload failed or didn’t return details.");
            }
            
            // This line should end the 'if (selectedFile)' block before proceeding
            setIsWaitingForResponse(false); // Set to false after file upload
            handleRemoveFile();
            // setInputText('');
        // Handling other modes such as ERP Connect, Record Meeting, BOM Analysis, Supply Matching and Analyze Documents
        if (isErpConnectMode) {
            addMessage("Connecting with your ERP now", "maia");
            // Call the ERP Connect API
            // Example: await callErpConnectApi();

        } else if (isRecordMeetingMode) {
            addMessage("Initiating meeting recording now", "maia");
            // Call the Record Meeting API
            // Example: await callRecordMeetingApi();
        }
    } else if (isSQSMode) {
        console.log("Selected Documents before extraction:", selectedDocuments);  // Log to verify the input
        // Extract only the document names from the selectedDocuments object
        const documentNames = Object.values(selectedDocuments);
        console.log("Extracted Document Names:", documentNames);  // Log the output to ensure extraction is working
    
        // Adjust mapping to ensure the supply source names are collected correctly
        const supplySourcesNames = selectedSupplySources.map(source => source.docId).join(", ");
        console.log("Supply Sources Names (using docId):", supplySourcesNames);
    
        if ((documentNames.length > 0 || inputText.trim() !== '') && selectedSupplySources.length > 0) {
            const userMessage = `${documentNames.join(', ')} ${documentNames.length > 0 ? 'and' : ''} ${inputText.trim() ? 'user entered demand information above and' : ''} ${supplySourcesNames} submitted for Supply Matching Analysis.`;  // Changed Line
            addMessage(userMessage, 'user');
    
            const payload = {
                supplySources: selectedSupplySources.map(source => source.docId),  // Use docId directly
                demandInformation: documentNames,  // Using extracted document names
                demandInformationInput: inputText.trim()  // Changed Line
            };

            // Start showing the loading spinner before the async operation
            setIsWaitingForResponse(true);
            
            console.log("Final payload for submission:", JSON.stringify(payload));  // Log the payload for verification
            await submitSupplyMatching(payload);  // Calling the existing function to submit the payload
        } else {
            console.log("No documents selected for supply matching.");
            addMessage("No documents or supply sources selected. Please select the necessary items to proceed.", 'user');
        }

        async function submitSupplyMatching(payload) { 
           try {
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/supplyMatching', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
        
                if (!response.ok) {
                    throw new Error(await response.text());
                }
        
                const data = await response.json();
                console.log("Backend response received:", data); // This will show you the exact structure and content of the backend response

                if (data && data.summary && Array.isArray(data.summary)) {
                    console.log("Data summary to be set:", data.summary);
                    setSupplyMatchingSummary(data.summary);
                    console.log("Attempting to update supplyMatchingSummary with:", data.summary);
        
                    if (data.message) {
                        const messageText = data.message;
                        addMessage(messageText, 'maia', {
                            viewSpreadsheetReport: data.spreadsheet_url,
                            viewSummary: data.spreadsheet_url ? true : false
                        }, [{
                            label: "View Summary",
                            action: () => {
                                setOverlayModalVisible(true);
                                setActiveTab('supplyMatchingSummary');
                            }                    
                        }]);
                    }
                } else {
                    throw new Error("Unexpected response format from supplyMatching or missing summary data.");
                }
            } catch (error) {
                console.error("Error occurred during Supply Matching:", error);
                addMessage(`Error occurred: ${error.message}`, 'maia');
            } finally {
                setIsWaitingForResponse(false);
                setIsSQSMode(false);
                setIsAnalyzeMode(false);
                setSelectedSupplySources([]);
                setSelectedDemandInformation({});

                // Reset isChecked state in uploadedDocuments
                setSelectedDocuments({});
                setUploadedDocuments(prevDocs => prevDocs.map(doc => ({ ...doc, isChecked: false })));
            }
        };
                
        } else if (isAnalyzeMode && isBomMode) {
            const documentIds = Object.keys(selectedDocuments);
            if (documentIds.length > 0) {
                const userMessage = `BOM Matching file submitted.`; // New line
                addMessage(userMessage, 'user'); // New Line
    
                await analyzeBom(documentIds, inputText); 
            } else {
                console.log("No documents selected for BOM analysis");
            }
            setIsWaitingForResponse(false); // Set to false after BOM analysis

            // Add these lines to reset selectedDocuments and isChecked state in uploadedDocuments
            setSelectedDocuments({});
            setUploadedDocuments(prevDocs => prevDocs.map(doc => ({ ...doc, isChecked: false })));
            setIsBomMode(false);       // Reset BOM mode
            setIsAnalyzeMode(false);   // Reset Analyze mode
        
        } else if (isAnalyzeMode) {
            const documentIds = Object.values(selectedDocuments).filter(id => id !== false);
            if (documentIds.length > 0 && inputText) {
                const analysisResponse = await analyzeDocuments(documentIds, inputText);
                console.log("Original Analysis Response:", analysisResponse); // Debug log

                // Check if analysisResponse is defined and is a string
                if (typeof analysisResponse === 'string') {
                    const modifiedResponse = analysisResponse.replace(inputText, '').trim();
                    console.log("Modified Response:", modifiedResponse); // Debug log
                    addMessage(modifiedResponse, 'maia');
                } else {
                    // Handle the case where analysisResponse is not as expected
                    console.error('Unexpected response from analyzeDocuments:', analysisResponse);
                    // Optionally, add a fallback message or handle the error differently
                }
                // Reset selected documents and exit analyze mode
                setSelectedDocuments({});  // Reset the selected documents
                setIsAnalyzeMode(false);   // Set isAnalyzeMode to false
                
                // Reset isChecked state in uploadedDocuments
                setUploadedDocuments(prevDocs => prevDocs.map(doc => ({ ...doc, isChecked: false })));

            } else {
                console.log("No documents selected for analysis");
            }
            setIsWaitingForResponse(false); // Set to false after document analysis
            
        } else {
            // Handling regular message sending logic
            const recentMessages = messages.slice(-5).map(msg => ({
                role: msg.sender === 'maia' ? 'assistant' : msg.sender, 
                content: msg.text
            }));
    
            const requestBody = {
                prompt: inputText,
                history: JSON.stringify(recentMessages)
            };
            setCallConversationAPI(false);
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/llmChat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                setCallConversationAPI(true);
                const responseBody = await response.json();
                const chatGptResponse = responseBody.responseText;
                addMessage(chatGptResponse, 'maia');
            }
            setIsWaitingForResponse(false); // Set to false after sending regular message
            setIsPending(false);
            setInputText('');
        }
        console.log("Updated messages state:", messages); // Log to check if messages state is updated

    };

    const callErpConnectApi = async (customerData) => {
        try {
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/maia_tech_webhook2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(customerData) // customerData should be an object with the necessary fields
            });
    
            if (!response.ok) {    
                console.error("Error in ERP Connect API:", await response.text());
                return null; // or handle error as appropriate
            }
    
            const data = await response.json();
            console.log("Response from ERP Connect API:", data);
            return data; // or handle data as needed
        } catch (error) {
            console.error("Error occurred during ERP Connect API call:", error);
            // Handle the error appropriately
        }
    };    
    
    const callRecordMeetingApi = async (clientNumber, userId) => {
        try {
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/make_and_record_call', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    client_number: clientNumber, // Client's phone number
                    uid: userId // User ID, if required
                })
            });
    
            if (!response.ok) {    
                console.error("Error in Record Meeting API:", await response.text());
                return null; // or handle error as appropriate
            }
    
            const data = await response.json();
            console.log("Response from Record Meeting API:", data);
            return data; // or handle data as needed
        } catch (error) {
            console.error("Error occurred during Record Meeting API call:", error);
            // Handle the error appropriately
        }
    };

    useEffect(() => {
        const handleAutomaticKeyPress = (transcriptText) => {
          if (transcriptText) {
            handleKeyPress({ key: "Tab", target: { value: transcriptText } });
          }
          if (transcriptText) {
            handleTextInputChange({
              key: "Tab",
              target: { value: transcriptText },
            });
          }
        };
    
        handleAutomaticKeyPress(transcriptText);
      }, [transcriptText]);
    
      const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          handleSendClick();
        }
      };

    const analyzeBom = async (selectedFiles) => {
        const blobNames = selectedFiles.map(fileId => selectedDocuments[fileId]);
        setIsWaitingForResponse(true); // Start showing loading spinner
    
        try {
            const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/bomAnalysis', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ blob_names: blobNames })
            });
    
            if (!response.ok) {    
                console.error("Error analyzing documents:", await response.text());
            }

            const data = await response.json();
            console.log("Response from bomAnalysis:", data);

            // Check if the response contains the expected properties
            if (data && data.message) {
            // Extract the message text and the URL for the 'View Results' button
                const messageText = data.message; // The text part of your message passed as a sting, which is the expected format for 'useMessageHistory' hook in 'MessageHistoryContext.js'.
                const messageUrls = {
                    viewBomAnalysis: data.html_url, // This is the URL for the 'View Results' button passed as an object, which is the expected format for 'useMessageHistory' hook in 'MessageHistoryContext.js'.
                    viewSpreadsheetReport: data.spreadsheet_url, // URL for the spreadsheet report
                    viewSummary: data.spreadsheet_url ? true : false,
                };
                // Call addMessage with the extracted text and urls
                addMessage(messageText, 'maia', messageUrls);          

            } else {
                console.error("Unexpected response format from bomAnalysis");
                // Handle the case where the response does not contain the expected properties
            }
        } catch (error) {
            console.error("Error occurred during BOM analysis:", error);
            addMessage(`Error occurred: ${error.message}`, 'maia');
        } finally {
            setIsWaitingForResponse(false); // Stop showing loading spinner
        }
    };

    // Define analyzeDocuments function
    const analyzeDocuments = async (documentIds, userQuery) => {
        console.log(`analyzeDocuments - Document IDs: ${JSON.stringify(documentIds)}, User Query: ${userQuery}`);
        if (userQuery) {
            try {
                const response = await fetch('https://us-central1-maia-tech.cloudfunctions.net/batchDocumentAnalysis', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ document_ids: documentIds, user_prompts: [userQuery] })
                });
                if (!response.ok) {
                    const errorText = await response.text();
                    console.error("Error analyzing documents:", errorText);
                    addMessage(`Error occurred: ${errorText}`, 'maia'); 
                } else {
                    const data = await response.json();
                    console.log("Response from batchDocumentAnalysis:", data);
                    if (data && data.responseText) {
                        const responseParts = data.responseText.split(/:\n\n/);
                        const maiaResponse = responseParts.length > 1 ? responseParts[1] : responseParts[0];
                        // New Line: Remove userQuery, ':' and extra line from Maia's response message
                        const cleanedResponse = data.responseText.replace(new RegExp(`^${userQuery}\\s*:?\\s*`, 'i'), '').trim();
                        addMessage(cleanedResponse, 'maia');
                    } else {
                        console.error("Unexpected response format from batchDocumentAnalysis");
                        addMessage("Unexpected response format", 'error'); // Handle the case where the response does not contain the expected properties
                    }
                }
            } catch (error) {
                console.error("Network or other error occurred:", error);
                addMessage(`Network or other error: ${error.message}`, 'error'); // Use the same function for network errors
            }
        } else {
            console.error("Missing document IDs or user query for analysis");
            addMessage("Missing document IDs or user query for analysis", 'error'); // Ensure missing input is also formatted
        }
    };

        // Define handleAnalyzeDocuments function here
        const handleAnalyzeDocuments = async () => {
            // You need to access the IDs of selected documents, ensure this data is available here
            const selectedDocumentIds = Object.keys(selectedDocuments);
            // Call the analyzeDocuments function with the selected IDs
            const userQuery = inputText; 
            // await analyzeDocuments(selectedDocumentIds, userQuery);
            const analysisResultsElement = await analyzeDocuments(selectedDocumentIds, userQuery);

            // Handle the results of the analysis here (e.g., adding a message to the chat)
           
        };
            
        // This useEffect manages the styled placeholder visibility
        useEffect(() => {
        // Function to handle the display of styledPlaceholder based on screen size and file selection
        const updateStyledPlaceholderVisibility = () => {
          // Check if the media query matches the smaller screen size
          const isSmallScreen = window.innerWidth <= 768;
          // Determine if the styledPlaceholder should be shown
          const shouldShowStyledPlaceholder = isSmallScreen && selectedFile != null;
          // Update the state accordingly
          setShowStyledPlaceholder(shouldShowStyledPlaceholder);
        };
      
        // Add the resize event listener
        window.addEventListener('resize', updateStyledPlaceholderVisibility);
      
        // Call the function to set the initial state based on the current conditions
        updateStyledPlaceholderVisibility();
      
        // Cleanup the event listener when the component is unmounted
        return () => window.removeEventListener('resize', updateStyledPlaceholderVisibility);
      }, [selectedFile]); // Depend on selectedFile to re-evaluate when it changes
      
        // This useEffect manages the placeholder text based on file attached, analyze mode, BomMode and SQSMode
        useEffect(() => {
            if (selectedFile) {
                setPlaceholderText('Your file is ready to upload.');
                setTextFieldClass('file-upload-mode');
            } else if (isBomMode && isAnalyzeMode) { // Check this condition first
                setPlaceholderText('Your file is ready for BOM Analysis, hit submit to generate analysis reports.');
                setTextFieldClass('bom-analyze-mode'); // Note the class name change to match your CSS
            } else if (isSQSMode && isAnalyzeMode) { // Then check this condition
                setPlaceholderText('Your files are ready for Supply Matching Analysis, hit submit to generate analysis reports.');
                setTextFieldClass('sqs-analyze-mode');
            } else if (isBomMode) { 
                setPlaceholderText('Select "BOM Matching" file from "Uploaded Files" using the Analysis button and then submit to generate analysis reports.');
                setTextFieldClass('bom-mode');
            } else if (isSQSMode) {
                setPlaceholderText('Enter demand information here or select a "demand" file from "Uploaded Files" using the Analysis button and then submit.');
                setTextFieldClass('sqs-mode');
            } else if (isAnalyzeMode) {
                setPlaceholderText('Enter your questions or prompts and submit to analyze the selected files.');
                setTextFieldClass('analyze-mode');
            } else {
                setPlaceholderText('Attach files, insert urls, speak or type commands');
                setTextFieldClass('');
            }        
        }, [selectedFile, isAnalyzeMode, isBomMode, isSQSMode]);        
      
      useEffect(() => {
         //To ensure the state is updated, add a useEffect to log the current state of isSendButtonEnabled:
        // console.log("isSendButtonEnabled:", isSendButtonEnabled);
      }, [isSendButtonEnabled]);

// The return statement will be your JSX for the input section
return (
    <div className="input-section"> {/* No analyze-mode class here */}
        {/* Radio button for file designation */}
        <div className="checkbox-option">
            <label>
                <input
                    type="checkbox"
                    name="fileDesignation" // Added a 'name' for clarity and accessibility
                    value="supplySource"
                    checked={isSupplySource}
                    onChange={(e) => setIsSupplySource(e.target.checked)} // This toggles the state based on whether the checkbox is checked or not
                    />
                Supply Source
            </label>
        </div>    
        {/*console.log("Rendering InputSection - textFieldClass:", textFieldClass)*/}
        <div className={`text-field-container ${showStyledPlaceholder ? 'show-styled-placeholder' : ''}`}>
            {/*<div className="styled-placeholder">Send file</div>*/}
            <textarea 
                ref={textFieldRef}
                className={`text-field ${textFieldClass}`}
                placeholder={placeholderText}
                style={textareaStyle} // Apply the dynamic styles here
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleTextInputChange}
                onKeyDown={!isPending ? handleKeyPress : undefined}
                value={inputText}  // Bind the value to inputText state
                // ... other props
            ></textarea>

            {/* Conditional rendering for Paperclip Icon or Analyze Indicator */}
            {isAnalyzeMode ? (
                // When in analyze mode, show the 'Analyze' indicator where the paperclip icon was
                <button className="analyze-indicator" onClick={handleAnalyzeDocuments}>
                    Analyze
                </button>
            ) : (           
                // When not in analyze mode, show the paperclip icon     
                <button className="option-btn paperclip-icon" onClick={handlePaperclipClick}>
                    <FontAwesomeIcon icon={faPaperclip} style={{ color: "#616161" }} />
                </button>
            )}

            {/* Icons container for Microphone and Send Button */}
            <div className="icons-container">
                <button className="option-btn microphone-icon" 
                    onClick={() => {
                        if (!browserSupportsSpeechRecognition) {
                          alert("Speech recognition is not supported in this browser.");
                          return;
                        }
                        // If speech recognition is supported, toggle the listening state
                        listening
                          ? SpeechRecognition.stopListening()
                          : SpeechRecognition.startListening();
                      }}
                      disabled={isPending}
                      title={listening ? "Mute" : "Unmute"}
                >
                    <FontAwesomeIcon
                      icon={listening ? faMicrophone : faMicrophoneSlash}
                      style={Colors}
                    />
                </button>
                <button
                    className={`option-btn send-icon ${selectedFile || isSendButtonEnabled ? 'file-attached' : ''}`} 
                    onClick={handleSendClick}
                    disabled={!isSendButtonEnabled}
                >
                    {selectedFile || isSendButtonEnabled ? 
                        <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white' }} /> : // Change 'white' to your desired color
                        <FontAwesomeIcon icon={faPaperPlane} />
                    }
                </button>
               </div> 
                {/* File Preview */}
                {selectedFile && (
                    <div className="file-preview-container">
                    <span className="file-name">{selectedFile.name}</span> {/* This will be styled by the CSS above */}
                    <button className="remove-attachment-btn" onClick={handleRemoveFile}>
                        &times; {/* This is a simple way to make a close icon */}
                    </button>
                    </div>
                )}
                </div>

            {/* File Attached Message */}
            <div className={`file-attached ${selectedFile ? 'show' : ''}`}>
                {fileAttachedMessage}
            </div>

            {/* Upload Message */}
            <div className="upload-message">
                {uploadMessage}
            </div>

            {/* File Input */}
            <input
                type="file"
                ref={fileInputRef} 
                style={{ display: 'none' }}  // Change from 'none' to 'block' for testing
                onChange={handleFileSelected} 
            />
        </div>
    );
}

export default InputSection;
