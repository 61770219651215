import React from 'react';

const SupplySourcesTab = ({ 
    supplySourceFiles, 
    setSupplySourceFiles,
    handleSupplyCheckboxChange, // Accept the prop with consistent naming
    selectedSupplySources,
    setHandleSendClickButton,
    handleSendClickButton,
    handleModalClose, // Receive the function to close the modal
    selectedDocuments, 
    inputText = '' // Provide a default value for inputText

}) => {
    console.log('supplySourceFiles:', supplySourceFiles);

    if (!supplySourceFiles) {
        console.error('supplySourceFiles is undefined');
        return <div>Error: No supply source files available.</div>;
    }

    const handleSubmit = () => {
        setHandleSendClickButton(true);

        // Uncheck all selected files
        setSupplySourceFiles(prevFiles => 
            prevFiles.map(file => ({ ...file, isChecked: false }))
        );

        handleModalClose();

    };

    return (
        <div>
            <h3>Supply Sources</h3>
            {supplySourceFiles.length ? (
                <ul>
                    {supplySourceFiles.map(source => (
                        <li key={source.id}>
                            <input 
                                type="checkbox"
                                checked={source.isChecked || false}
                                onChange={(e) => handleSupplyCheckboxChange(source.name, e.target.checked)}
                            /> {source.name}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No supply sources available.</p>
            )}
            <button
                onClick={handleSubmit}
                disabled={selectedSupplySources.length === 0 || !(Object.keys(selectedDocuments).length > 0 || inputText.trim() !== '')} // Updated logic to enable/disable the button
                style={{ marginTop: '20px' }}>
                Submit
            </button>
        </div>
    );
};

export default SupplySourcesTab;
