// ChatLogic.js: Centralizes the core logic for sending and updating messages. It provides the `sendMessage` and `updateChatMessage` functions. It uses the `useMessageHistory` hook  
// to access the `addMessage` and `updateMessage` functions from `MessageHistoryContext`.

// Remove the unused import
// import { useContext } from 'react';
import { useMessageHistory } from '../message/MessageHistoryContext'; 

let messageIdCounter = 0; 
const generateMessageId = () => { // Insert this function here
    return `${Date.now()}-${messageIdCounter++}`;
    };   


// 'useChatLogic is the main hook that will contain all the chat-related logic.
const useChatLogic = () => {
const { addMessage, updateMessage } = useMessageHistory(); // Get the `addMessage` and `updateMessage` functions from `MessageHistoryContext`

// Function to handle sending a new message. It generates a unique ID for each message and then uses the `addMessage` function from `MessageHistoryContext`.
const sendMessage = (text, sender, urls) => {
// Generate a unique ID for the message
const messageId = generateMessageId(); 
console.log(`Sending message with ID: ${messageId}`); 
addMessage(text, sender, urls, messageId);
return messageId; // Return the messageId so that the calling function can use it to update the message later
};

// Function to handle updating an existing message. It takes a message ID and new text as arguments and uses the `updateMessage` function from `MessageHistoryContext`.
const updateChatMessage = (messageId, newText) => {
console.log(`Updating message with ID: ${messageId}`);
updateMessage(messageId, newText);
};
// Add more functionalities as needed...

return { sendMessage, updateChatMessage };
};

export default useChatLogic;