// TopNav.js

import React from 'react';
import { useMessageHistory } from '../message/MessageHistoryContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faPlus }   from '@fortawesome/free-solid-svg-icons';

function TopNav({ isSidebarVisible,toggleSidebar, isChatHistoryVisible, toggleChatHistory }) {
  const { clearMessages } = useMessageHistory();

    // Function to handle clicking the new chat icon
    const handleNewChatIconClick = () => {
        // Logic to initiate a new chat
        clearMessages();
        localStorage.removeItem("current_conversation_id");
    };

    return (
      <div className={`top-nav ${isSidebarVisible ? "" : "hidden"}`}>
        <div className="top-nav-icon" onClick={toggleSidebar}>
          {/* ☰ Placeholder for the chat history icon */}
          {isSidebarVisible ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div className="top-nav-logo">
          <img
            src="https://storage.googleapis.com/webui-assets/Transparent%20Logo%20-%20no%20space.png"
            alt="Maia Logo"
            id="top-nav-logo"
          />
        </div>
        <div className="top-nav-icon" onClick={toggleChatHistory}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        {/* onClick={handleNewChatIconClick} */}
      </div>
    );
}

export default TopNav;