// AuthContext.js 
// Provides autehntication state accross the application. It uses 'onAuthStateChanged' to listen from Firebase to track the  
// user's authentication status and provide context that can be consumed by components like 'Login.js' and 'Register.js'.
// AuthContext is created using 'createContext', which is later exported and used by useAuth for easier access within components.
// AuthProvider is a context provider component that wraps your application's components, making the auth state available to any component that calls useAuth.
// Inside AuthProvider, there is an observer on the Firebase Auth object with onAuthStateChanged. This observer is called whenever the user's sign-in state changes.
// When the authentication state changes (a user logs in or out), the observer updates currentUser with the new user object, or null if the user signs out.
// The loading state ensures that the application renders children only after the initial authentication status is retrieved, 
// preventing any flicker or unauthorized access to components that require an authenticated user.

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../../FirebaseConfig'; // Import db 
import { onAuthStateChanged, signOut } from 'firebase/auth'; 
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Use the auth object directly with onAuthStateChanged
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) { 
        const userDoc = await getDoc(doc(db, "users", user.uid)); 
        if (userDoc.exists() && userDoc.data().isAdmin) { 
          setIsAdmin(true); 
        } else { 
          setIsAdmin(false); 
        } 
      } else { 
        setIsAdmin(false); 
      } 
      setLoading(false);
    });
  
    // Return the unsubscribe function to ensure we unsubscribe from the listener when the component unmounts
    return unsubscribe;
  }, []);

    // Logout function
    const logout = async () => {
      await signOut(auth);
    };
    
    const value = {
      currentUser,
      isAdmin, 
      loading,
      logout, 
    };
  
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
