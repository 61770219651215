// components/settingsModal/SettingsModal.js

import React, { useState, useEffect } from 'react';
import Profile from './Profile'; // Import the Profile component
import Contacts from './Contacts';
import { doc, getDoc } from 'firebase/firestore';  // Import Firestore methods
import { db } from '../../FirebaseConfig';  // Import Firebase config
import './SettingsModal.css'; // Import your CSS for styling

const SettingsModal = ({ isVisible, onClose, currentUser, activeTab }) => {
  const [tab, setTab] = useState(activeTab || 'profile'); // Initialize with the activeTab prop or default to 'profile'
  const [userPhoneNumber, setUserPhoneNumber] = useState('');  // State to store user's phone_number

  useEffect(() => {
    setTab(activeTab); // Update the tab when activeTab prop changes
  }, [activeTab]);

  useEffect(() => {
    const fetchUserPhoneNumber = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserPhoneNumber(userDoc.data().phone_number);  // Store user phone_number in state
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user number:", error);
        }
      }
    };

    fetchUserPhoneNumber();
  }, [currentUser]);

  if (!isVisible) return null;

  return (
    <div className="settingsModal-modal">
      <div className="settingsModal-content">
        <div className="settingsModal-header">
          <h2>Settings</h2>
          <button type="button" className="settingsModal-close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="settingsModal-body">
          <ul className="settingsModal-tabs">
            <li className={tab === 'profile' ? 'active' : ''} onClick={() => setTab('profile')}>
              Profile
            </li>
            <li className={tab === 'contacts' ? 'active' : ''} onClick={() => setTab('contacts')}>
              Contacts
            </li>
            <li className={tab === 'settings' ? 'active' : ''} onClick={() => setTab('settings')}>
              Settings
            </li>
            <li className={tab === 'speech' ? 'active' : ''} onClick={() => setTab('speech')}>
              Speech
            </li>
            <li className={tab === 'dataControls' ? 'active' : ''} onClick={() => setTab('dataControls')}>
              Data
            </li>
          </ul>
          <div className="settingsModal-tab-content">
            {tab === 'profile' && <Profile currentUser={currentUser} onClose={onClose} />}
            {tab === 'settings' && <div>General Settings</div>}
            {tab === 'speech' && <div>Speech Settings</div>}
            {tab === 'dataControls' && <div>Data Controls Settings</div>}
            {tab === 'contacts' && <Contacts currentUser={currentUser} userPhoneNumber={userPhoneNumber} onClose={onClose} />}
            </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
