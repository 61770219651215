// ForgotPassword.js

import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from 'react-router-dom';
import '../auth/AuthStyles.css'; 


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('Check your inbox for further instructions');
      })
      .catch((error) => {
        setError('Failed to reset password');
        console.error(error);
      });
  };

    return (
        <div className="forgot-password-container">
            <h1>Reset Password</h1>
            {error && <p className="error">{error}</p>}
            {message && <p className="success">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input 
                        type="email" 
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="Email Address"
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Reset Password</button>
            </form>
            {/* Link to navigate back to the login page */}
            <p>
                <Link to="/login" className="link">Back to Login</Link>
            </p>
        </div>
    );
};

export default ForgotPassword;
