import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

function ArchiveModal({ open, handleClose, userId, fetchHistoryData }) {
  const [archivedList, setArchivedList] = useState([]);
  useEffect(() => {
    if (open) {
      fetchArchivedList();
    }
  }, [open]);

  const fetchArchivedList = () => {
    axios
      .get(
        `https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/archived`,
        {
          params: { userId: userId },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          setArchivedList(res.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching archived list:", error);
      });
  };

  const handleUnarchive = (conversationId) => {
    axios
      .post(
        `https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/unarchive`,
        {
          userId: userId,
          conversationId: conversationId,
        }
      )
      .then((res) => {
        if (res.data.success) {
          fetchArchivedList();
          fetchHistoryData();
        }
      })
      .catch((error) => {
        console.error("Error unarchiving conversation:", error);
      });
  };

  const getShortenedTitle = (title, wordLimit) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return title;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
    >
      <Box>
        <DialogTitle id="alert-dialog-title">Archive List</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {archivedList.length > 0 ? (
              archivedList.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ padding: 0, margin: 0 }}>
                    {" "}
                    {index + 1}. {getShortenedTitle(item.title, 10)}
                  </p>
                  <Tooltip title="unarchive">
                    <IconButton
                      aria-label="unarchive"
                      // color="primary"
                      sx={{ color: "gray" }}
                      onClick={() => handleUnarchive(item.id)}
                    >
                      <UnarchiveIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ))
            ) : (
              <p>not avilable</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} sx={{ textTransform: "none" }} autoFocus>
            close{" "}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ArchiveModal;
