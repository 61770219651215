import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Chat.css";
import axios from "axios";
import { doc } from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import { Button, IconButton, MenuItem, MenuList, ArchiveIcon, Popover, TextField, Tooltip, Typography } from '../MuiComponents';
import { ReactComponent as NewChatIcon } from "../assets/edit-edit-3.svg";
import {
  faRightFromBracket,
  faTimes,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMessageHistory } from "../message/MessageHistoryContext";
import IosShareIcon from "@mui/icons-material/IosShare";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InventoryIcon from "@mui/icons-material/Inventory";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { toast } from "react-toastify";
import ArchiveModal from "./ArchiveModal";
import ShareUrlModal from "./ShareUrlModal";
import { useNavigate, useParams } from "react-router-dom";

const ChatHistory = ({
  currentUser,
  uploadedDocuments,
  setUploadedDocuments,
  isAnalyzeMode,
  setIsAnalyzeMode,
  isBomMode,
  setIsBomMode,
  setClearInput,
  callConversationAPI,
  isChatHistoryVisible,
  toggleChatHistory,
  isWaitingForResponse, 
  setIsWaitingForResponse
}) => {
  const { conversationId } = useParams(); // Get the conversationId from the URL
  const {
    clearMessages,
    addMessage,
    clearedMessages,
    setMessagesFromConversation,
    messages,
  } = useMessageHistory();
  const [conversation, setConversation] = useState(messages);
  const [getConversationByID, setGetConversationByID] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const userDocRef = doc(db, "users", currentUser?.uid ?? "0");
  const sidebarRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverConversationId, setPopoverConversationId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const popoverTop = anchorEl?.getBoundingClientRect().top || 0;
  const popoverLeft = anchorEl?.getBoundingClientRect().left || 0;
  const buttonRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renameConversationId, setRenameConversationId] = useState(null);
  const [renameInputValue, setRenameInputValue] = useState("");
  const textFieldRef = useRef(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const logoStyles = {
    height: "50px",
    width: "45%",
  };

  // useEffect(() => {
  //   if (renameConversationId && textFieldRef.current) {
  //     textFieldRef.current.focus();
  //   }
  // }, [renameConversationId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isChatHistoryVisible &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        toggleChatHistory();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChatHistoryVisible]);

  useEffect(() => {
    if (currentUser) {
      fetchHistoryData();
    }
  }, []);

  useEffect(() => {
    if (callConversationAPI) {
      fetchHistoryData();
    }
  }, [callConversationAPI]);

  const fetchHistoryData = () => {
    setIsLoading(true);
    axios
      .post(
        "https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations",
        { userId: userDocRef.id }
      )
      .then((res) => {
        if (res.data.success === true) {
          setConversation(res.data.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((error) => {
        console.log("error fetch all the conversation", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false once data is fetched
      });
  };

  const handleClick = () => {
    if (!isWaitingForResponse) {
      clearMessages();
      fetchHistoryData();
      setMessagesFromConversation([])
      localStorage.removeItem("current_conversation_id");
    }
    // if (!isWaitingForResponse) {
    //   clearMessages(); // Clear current messages
    //   setUploadedDocuments([]); // Reset uploaded documents if needed
    //   setIsAnalyzeMode(false); // Reset analyze mode
    //   setIsBomMode(false); // Reset BOM mode
    //   setMessagesFromConversation([]); // Clear conversation messages
    //   localStorage.removeItem("current_conversation_id");
    //   setIsWaitingForResponse(false); // Reset waiting for response state
    //   fetchHistoryData(); // Fetch new chat history
    // }
  };

  const categorizeMessages = (messages) => {
    const categorized = {
      today: [],
      yesterday: [],
      previous7Days: [],
      previous30Days: [],
    };

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    messages.forEach((conversation) => {
      if (conversation.updated) {
        const timestamp = conversation.updated;
        const messageDate = new Date(
          timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
        );
        const categorizedItem = {
          conversation_id: conversation.conversation_id,
          title: conversation.title,
          timestamp: messageDate,
        };

        if (messageDate >= now) {
          categorized.today.push(categorizedItem);
        } else if (messageDate >= new Date(now - 24 * 60 * 60 * 1000)) {
          categorized.yesterday.push(categorizedItem);
        } else if (messageDate >= new Date(now - 7 * 24 * 60 * 60 * 1000)) {
          categorized.previous7Days.push(categorizedItem);
        } else if (messageDate >= new Date(now - 30 * 24 * 60 * 60 * 1000)) {
          categorized.previous30Days.push(categorizedItem);
        }
      }
    });
    categorized.today.sort((a, b) =>
      b.timestamp < a.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0
    );
    return categorized;
  };

  // const organizedChatHistory = categorizeMessages(conversation);
  const organizedChatHistory = useMemo(
    () => categorizeMessages(conversation),
    [conversation]
  );

  useEffect(() => {
    const storedConversationId = localStorage.getItem(
      "current_conversation_id"
    );
    if (storedConversationId) {
      setCurrentConversationId(storedConversationId);
      fetchConversationData(storedConversationId);
    }
  }, []);

  const fetchConversationData = (conversationId) => {
    setIsLoading(true);
    axios
      .post(
        "https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/messages",
        {
          userId: userDocRef.id,
          conversationId: conversationId,
        }
      )
      .then((res) => {
        const conversationData = res.data.data || [];
        setMessagesFromConversation(conversationData);
        // fetchHistoryData();
        if (Array.isArray(conversationData.messages)) {
          conversationData.messages.forEach((messageItem) => {
            const { id, text, sender, urls } = messageItem;
            addMessage(text, sender, urls, id);
          });
        }
      })
      .catch((err) => {
        console.log("Error fetching conversation data:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTextClick = (conversationId) => {
    // setSelectedConversationId(conversationId);
    // localStorage.setItem("current_conversation_id", conversationId);
    // if (conversationId) {
    //   fetchConversationData(conversationId);
    // }
    if (!isWaitingForResponse) {
      setSelectedConversationId(conversationId);
      localStorage.setItem("current_conversation_id", conversationId);
      if (conversationId) {
        fetchConversationData(conversationId);
      }
    }
    // navigate(`/chat/${conversationId}`);
  };

  const handleOptionsClick = (event, conversationId) => {
    setAnchorEl(event.currentTarget);
    setPopoverConversationId(conversationId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverConversationId(null);
  };

  const getShortenedText = (text, wordLimit) => {
    const words = text.split(" ");
    const shortenedWords = words.slice(0, wordLimit).map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const shortenedText = shortenedWords.join(" ");

    if (words.length > wordLimit) {
      return `${shortenedText}...`;
    }
    return shortenedText;
  };

  useEffect(() => {
    textFieldRef.current?.focus();
  }, [textFieldRef.current]);

  const handleRenameClick = (conversationId) => {
    setRenameConversationId(conversationId);
    handleOptionsClick(conversationId);
    const conversationToUpdate = conversation.find(
      (conv) => conv.conversation_id === conversationId
    );
    setRenameInputValue(conversationToUpdate.title);
  };

  const handleRenameChange = (e) => {
    setRenameInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (renameInputValue.trim() !== "") {
      handleRename(renameConversationId, renameInputValue);
    } else {
      setRenameConversationId(null);
      setRenameInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur(event);
    }
  };

  const handleRename = (conversationId, newTitle) => {
    axios
      .put(
        "https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/rename",
        {
          userId: userDocRef.id,
          conversationId: conversationId,
          newTitle: newTitle,
        }
      )
      .then((res) => {
        if (res.data.success) {
          fetchHistoryData();
          setRenameConversationId(null);
          setRenameInputValue("");
        } else {
          // toast.error("Failed to rename conversation");
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log("Error renaming conversation:", error);
        toast.error("Failed to rename conversation");
      });
  };

  const ConversationList = ({
    conversations,
    handleTextClick,
    titleKey = "title",
  }) => (
    <div style={{ marginTop: "0.15rem" }}>
      {conversations.map((conversation) => (
        <div key={conversation.conversation_id} className="conversation-item">
          <div className="conversation-content">
            {renameConversationId === conversation.conversation_id ? (
              <TextField
                key={`text-${conversation.conversation_id}`}
                type="text"
                variant="standard"
                size="small"
                value={renameInputValue}
                onChange={handleRenameChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                inputRef={(e) => {
                  textFieldRef.current = e;
                }}
              />
            ) : (
              <p
                onClick={() => handleTextClick(conversation.conversation_id)}
                className={
                  localStorage.getItem("current_conversation_id") ===
                  conversation.conversation_id
                    ? "selected"
                    : "historyList"
                }
              >
                {getShortenedText(conversation[titleKey], 5)}
              </p>
            )}
            <Tooltip title="Options">
              <FontAwesomeIcon
                icon={faEllipsis}
                ref={buttonRef}
                onClick={(event) =>
                  handleOptionsClick(event, conversation.conversation_id)
                }
                className="options-icon"
              />
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );

  const handleDeleteClick = (event) => {
    setAnchorEl(null);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = (conversationId) => {
    // Call delete API here with conversationId
    console.log("Deleting conversation with ID:", conversationId);
    axios
      .delete(
        `https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/delete`,
        {
          params: { conversationId: conversationId },
          data: { userId: userDocRef.id },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          console.log(res.data.message);
          fetchHistoryData();
          setIsDeleteModalOpen(false);
          handleClose(); // Close popover

          handleClick();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
          console.log("something went wrong");
        }
      })
      .catch((error) => {
        console.log("error fetch all the conversation", error);
      });
    // setIsDeleteModalOpen(false);
    // handleClose(); // Close popover
  };

  const archiveConversation = async (conversationId) => {
    try {
      const response = await axios.post(
        "https://us-central1-maia-tech.cloudfunctions.net/chatHistory/api/conversations/archive",
        {
          userId: userDocRef.id,
          conversationId: conversationId,
        }
      );
      if (response.data.success) {
        fetchHistoryData();
        handleClick();
        toast.success(response.data.message);
      } else {
        console.log("Something went wrong while archiving");
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error archiving conversation:", error);
      toast.error("Error archiving conversation");
    } finally {
      handleClose();
    }
  };

  const handleShareClick = (conversationId) => {
    setAnchorEl(null);
    const url = `${window.location.origin}/chat/${conversationId}`;
    setShareUrl(url);
    setShareModalOpen(true);
  };

  return (
    <>
      <div style={{ width: "80%" }} ref={sidebarRef}>
        <div className="new-chat">
          <div>
            <Tooltip title="close chat-history">
              <FontAwesomeIcon
                icon={faTimes}
                className="chat-sidebar-close-button"
                onClick={toggleChatHistory}
              />
            </Tooltip>
            <span className="chat-text">Chat History</span>
            <div className="chatIcon">
              <Tooltip title="New Chat">
                <NewChatIcon
                  width="24"
                  height="24"
                  onClick={() => handleClick()}
                  style={{ cursor: "pointer" }}
                  // onClick={isWaitingForResponse ? null : () => handleClick()}
                  // style={{ cursor: isWaitingForResponse ? "not-allowed" : "pointer", opacity: isWaitingForResponse ? 0.5 : 1 }}            
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <hr />
        <div className="sidebar">
          <div className="sidebar-content">
            <div style={{ marginTop: "0.25rem", marginBottom: "1.25rem" }}>
              <h4 className="days">Today</h4>
              <ConversationList
                conversations={organizedChatHistory.today}
                handleTextClick={handleTextClick}
              />
            </div>
            <div style={{ marginTop: "0.25rem", marginBottom: "1.25rem" }}>
              <h4 className="days">Yesterday</h4>
              <ConversationList
                conversations={organizedChatHistory.yesterday}
                handleTextClick={handleTextClick}
              />
            </div>
            <div style={{ marginTop: "0.25rem", marginBottom: "1.25rem" }}>
              <h4 className="days">Previous 7 Days</h4>
              <ConversationList
                conversations={organizedChatHistory.previous7Days}
                handleTextClick={handleTextClick}
              />
            </div>
            <div style={{ marginTop: "0.25rem", marginBottom: "1.25rem" }}>
              <h4 className="days">Previous 30 Days</h4>
              <ConversationList
                conversations={organizedChatHistory.previous30Days}
                handleTextClick={handleTextClick}
              />
            </div>
          </div>
        </div>
        <div className="sidebar-footer">
          <Button variant="text" color="primary" startIcon={<ArchiveIcon />} onClick={handleOpenModal}>
            Archive List
          </Button>
        </div>
      </div>

      <Popover
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        // anchorEl={anchorEl}
        anchorEl={() => buttonRef.current}
        ref={buttonRef}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorPosition={{ top: popoverTop, left: popoverLeft }}
        slotProps={{
          sx: { "& .MuiPopover-paper": { borderRadius: "15px !important" } },
        }}
        // sx={{ borderRadius: "15px !important" }}
      >
        <MenuList>
          <MenuItem
            onClick={() => handleShareClick(popoverConversationId)}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <IosShareIcon /> <Typography>Share</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleRenameClick(popoverConversationId)}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <DriveFileRenameOutlineIcon /> <Typography>Rename</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => archiveConversation(popoverConversationId)}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <InventoryIcon /> <Typography>Archive</Typography>
          </MenuItem>
          <MenuItem
            onClick={handleDeleteClick}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "red",
            }}
          >
            <DeleteOutlineIcon /> <Typography>Delete</Typography>
          </MenuItem>
        </MenuList>
      </Popover>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        conversationId={popoverConversationId}
      />

      <ArchiveModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        userId={userDocRef.id}
        fetchHistoryData={fetchHistoryData}
      />

      <ShareUrlModal
        open={shareModalOpen}
        handleClose={() => setShareModalOpen(false)}
        shareUrl={shareUrl}
      />
    </>
  );
};

export default ChatHistory;
