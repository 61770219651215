// AppContext.js
// AppContext.js is a custom React context that contains the selected documents.

import React, { createContext, useState } from 'react';

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
    const [selectedDocuments, setSelectedDocuments] = useState({});

    // The value that will be supplied to the descendants
    const contextValue = {
        selectedDocuments,
        setSelectedDocuments,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
