//App.js is the main file of the project. It is the first file that is executed when the project is run.

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/main/Layout';
import LoginLayout from './components/login/LoginLayout'; // Import LoginLayout
import { AuthProvider } from './components/auth/AuthContext'; // Import AuthProvider
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import Admin from './components/admin/Admin'; // Import the Admin component
import PrivateRoute from './routes/PrivateRoute'; // Import PrivateRoute # New Line
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SharedConversation from './components/chats/SharedConversation';

function App() {
  return (
    <>
    <AuthProvider> {/* Wrap Layout with AuthProvider */}
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginLayout />} />
            <Route path="/register" element={<LoginLayout />} />
            <Route path="/*" element={<Layout />} />
            {/* <Route path="/chat/:conversationId" element={<Layout />} /> */}
            <Route path="/chat/:conversationId" element={<SharedConversation />} />
            <Route path="/forgot-password" element={<LoginLayout />} />
            <Route path="/admin" element={<PrivateRoute element={<Admin />} />} /> {/* New Line */}
            <Route path="/*" element={<Layout />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </AuthProvider>
    </>
  );
}

export default App;
