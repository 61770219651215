// components/settingsModal/Profile.js

import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';
import { TextField, Button, MenuItem, IconButton, InputAdornment, Visibility, VisibilityOff } from '../MuiComponents'; // Updated import


const Profile = ({ currentUser, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); 
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('United States');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [ssn, setSsn] = useState(''); 
  const [showSsn, setShowSsn] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        const userDocRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setFirstName(userData.first_name);
          setLastName(userData.last_name);
          setEmail(userData.email);
          setPhoneNumber(userData.phone_number || ''); 
          setZipCode(userData.zip_code || '');
          setCountry(userData.country || 'United States');
          setDateOfBirth(userData.date_of_birth || '');
          setSsn(userData.ssn || ''); 
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  const handleSaveChanges = async () => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber, 
        zip_code: zipCode,
        country: country,
        date_of_birth: dateOfBirth,
        ssn: ssn 
      });
      onClose();
    }
  };

  return (
    <div>
      <form>
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Phone Number"
          value={phoneNumber}  
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Zip/Postal Code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          select
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="United States">United States</MenuItem>
          <MenuItem value="Canada">Canada</MenuItem>
          <MenuItem value="United Kingdom">United Kingdom</MenuItem>
          {/* Add other countries as needed */}
        </TextField>

        <TextField
          label="Date of Birth"
          type="date"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="Last 4 of SSN"
          type={showSsn ? 'text' : 'password'} // Toggle between text and password
          value={ssn}
          onChange={(e) => setSsn(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle ssn visibility"
                  onClick={() => setShowSsn(!showSsn)} // Toggle visibility
                  edge="end"
                >
                  {showSsn ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default Profile;
