// Admin.js

import React, { useState, useEffect } from 'react';
import { db } from '../../FirebaseConfig';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';

const Admin = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userDocs = await getDocs(collection(db, "users"));
      setUsers(userDocs.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchUsers();
  }, []);

  const approveUser = async (userId) => {
    await updateDoc(doc(db, "users", userId), { approved: true });
    setUsers(users.map(user => user.id === userId ? { ...user, approved: true } : user));
  };

  const removeApproval = async (userId) => {  
    await updateDoc(doc(db, "users", userId), { approved: false });  
    setUsers(users.map(user => user.id === userId ? { ...user, approved: false } : user));  
  };  

  return (
    <div>
      <h2>User Approval</h2>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.first_name} {user.last_name} ({user.email}) - 
            <span style={{ marginRight: '10px' }}>Approved: {user.approved ? "Yes" : "No"}</span>
            {!user.approved ? (  
              <button onClick={() => approveUser(user.id)}>Approve</button>  
            ) : (  
              <button onClick={() => removeApproval(user.id)}>Remove Approval</button>  
            )}  
          </li>
        ))}
      </ul>
    </div>
  );  
};

export default Admin;
