// OverlayModal.js - This file defines the OverlayModal component, which serves as a generic modal container. It is responsible for:
// Displaying the Modal: It controls the visibility and the basic styling of the modal overlay that appears over your main application content.
// Managing Close Functionality: It includes a close button and the logic to hide the modal when the button is clicked.
// Hosting Child Components: The component is designed to receive child components as props, allowing it to display different content dynamically. This is done via the children prop, which can render anything passed to it, such as forms, tabs, or information panels.

import React, { useState, useEffect, useCallback } from 'react';
import './OverlayModal.css';
import SupplySourcesTab from './SupplySourcesTab';
import SupplyMatchingSummaryTab from './SupplyMatchingSummaryTab';
import RFQEmailsTab from './RFQEmailsTab';


const OverlayModal = ({ // Accept props
    isVisible, 
    onClose, 
    selectedSupplySources,
    setSelectedSupplySources,
    supplySourceFiles,
    setSupplySourceFiles,
    fetchSupplySourceFiles, 
    handleSupplyCheckboxChange,
    handleSupplySourcesClick, 
    supplyMatchingSummary, 
    setIsWaitingForResponse, 
    isWaitingForResponse,  
    handleSendClickButton, 
    setHandleSendClickButton,
    selectedDocuments,
    activeTab,
    setActiveTab,
    inputText
}) => {

    const [rfqEmailData, setRfqEmailData] = useState([]);  // Manage RFQ email data state here in OverlayModal
    const [isSendingAll, setIsSendingAll] = useState(false);  // New state variable for 'Send All' loading indicator
    const [sentEmailIndices, setSentEmailIndices] = useState([]);  // New state variable to track sent emails
    const [sendingEmails, setSendingEmails] = useState([]);  // New state variable to track which emails are 
    const [resetSuccess, setResetSuccess] = useState(false);  // New state variable to reset success messages
    const [isDeletingAll, setIsDeletingAll] = useState(false); // New state for Delete All button
    const [deletedEmailIndices, setDeletedEmailIndices] = useState([]);  // New state to track deleted emails
    const [deletingEmails, setDeletingEmails] = useState([]); // New state for individual Delete buttons

    const handleEmailChanges = useCallback((index, updatedFields) => {  // This line has no changes
        setRfqEmailData(currentData => 
            currentData.map((email, idx) => 
                idx === index ? { ...email, ...updatedFields } : email  // New Line
    )
        );
    }, []); // Dependency array is empty, indicating this function does not depend on any props or state variables
       
    // Function to delete an email from the list
    const deleteEmail = async (index) => {
        setDeletingEmails(prev => [...prev, index]);  // Add the email index to the deleting list
        await new Promise(resolve => setTimeout(resolve, 500)); // Simulate an async operation
        setRfqEmailData(currentData => currentData.filter((_, idx) => idx !== index));
        setDeletingEmails(prev => prev.filter(idx => idx !== index));  // Remove from deleting list after deletion
        setDeletedEmailIndices(prev => [...prev, index]);  // Mark email as deleted
        setTimeout(() => {
            setDeletedEmailIndices(prev => prev.filter(idx => idx !== index));  // Remove the success message after some time
        }, 3000);    
    };

    const clearAllEmails = async () => {
        setIsDeletingAll(true);
        await new Promise(resolve => setTimeout(resolve, 500)); // Simulate an async operation
        setRfqEmailData([]);
        setIsDeletingAll(false);
    };


    // Define the sendEmail function
    const sendEmail = (email, index) => {
        setSendingEmails(prev => [...prev, index]);  // Add the email index to the sending list
        fetch('https://us-central1-maia-tech.cloudfunctions.net/sendEmail', { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(email),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Email sent successfully:', data);
            setSentEmailIndices(prev => [...prev, index]);  // Mark email as sent
            setTimeout(() => {
                setRfqEmailData(currentData => currentData.filter((_, idx) => idx !== index));
            }, 3000);  // Remove email after showing success message
        })
        .catch(error => {
            console.error('Error sending email:', error);
        })
        .finally(() => { //New Line
            setSendingEmails(prev => prev.filter(idx => idx !== index));  // Remove from sending list
        });
    };

    // Line: Log the type of setIsWaitingForResponse as soon as OverlayModal loads, can remove this once 'isWeatingForResponse' is confirmed to work
    useEffect(() => {
        console.log("OverlayModal - Received setIsWaitingForResponse, type:", typeof setIsWaitingForResponse);
    }, []); // Empty dependency array to run only on component mount

    // This useEffect can be removed when tab-switching is confirmed to work flawlessly (may remove after getting UI to behave in optimal ways)
    useEffect(() => {
        console.log("Active tab now:", activeTab);
    }, [activeTab]);
    
    useEffect(() => {
        if (isVisible && activeTab === 'supplySources') {
            if (!supplySourceFiles || supplySourceFiles.length == 0) {
                fetchSupplySourceFiles();
            }
        }
    }, [isVisible, activeTab]);

    useEffect(() => {
        if (resetSuccess) {
            setSentEmailIndices([]);
            setResetSuccess(false);
        }
    }, [resetSuccess]);

    if (!isVisible) return null;
  
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <ul className="modal-tabs">
                        <li className={activeTab === 'supplySources' ? 'active' : ''} onClick={() => setActiveTab('supplySources')}>
                            Supply Sources
                        </li>
                        <li className={activeTab === 'supplyMatchingSummary' ? 'active' : ''} onClick={() => setActiveTab('supplyMatchingSummary')}>
                            Supply Matching Summary
                        </li>
                        <li className={activeTab === 'rfqEmails' ? 'active' : ''} onClick={() => setActiveTab('rfqEmails')}>
                            RFQ Emails
                        </li>
                    </ul>
                    <button type="button" className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    {activeTab === 'supplySources' && (
                        <div className="supply-sources-content">
                            <SupplySourcesTab
                                supplySourceFiles={supplySourceFiles}
                                setSupplySourceFiles={setSupplySourceFiles} 
                                handleSupplyCheckboxChange={handleSupplyCheckboxChange}
                                selectedSupplySources={selectedSupplySources}
                                setSelectedSupplySources={setSelectedSupplySources} 
                                handleSendClickButton={handleSendClickButton} 
                                setHandleSendClickButton={setHandleSendClickButton}
                                handleModalClose={onClose}
                                selectedDocuments={selectedDocuments}
                                fetchSupplySourceFiles={fetchSupplySourceFiles} 
                                handleSupplySourcesClick={handleSupplySourcesClick} 
                                inputText={inputText} // Pass the inputText state to SupplySourcesTab                          
                            />
                        </div>
                    )}
                    {activeTab === 'supplyMatchingSummary' && (
                        <SupplyMatchingSummaryTab
                            supplyMatchingSummary={supplyMatchingSummary}
                            setRfqEmailData={setRfqEmailData}  
                            setIsWaitingForResponse={setIsWaitingForResponse} 
                            isWaitingForResponse={isWaitingForResponse}  
                            setActiveTab={setActiveTab} 
                        />
                    )}
                    {activeTab === 'rfqEmails' && (
                        <RFQEmailsTab
                            rfqEmailData={rfqEmailData}
                            handleEmailChanges={handleEmailChanges} 
                            sendEmail={sendEmail}
                            deleteEmail={deleteEmail} 
                            clearAllEmails={clearAllEmails} 
                            isSendingAll={isSendingAll}  
                            sendingEmails={sendingEmails}  
                            sentEmailIndices={sentEmailIndices}  
                            setResetSuccess={setResetSuccess}  
                            isDeletingAll={isDeletingAll} // Pass the 'Delete All' loading state
                            deletingEmails={deletingEmails} // Pass the deleting emails state
                            deletedEmailIndices={deletedEmailIndices}  // Pass the deleted emails state
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default OverlayModal;
